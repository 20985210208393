import { useEffect, useState, useContext, Fragment, useRef } from 'react';
import { HeaderContext } from './api/api-context';
import { Header, PageTitle, currentTime, slotToTime, Loading } from './utils';
import { Calendar, notification, Drawer, Button, Input } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

import PhoneInput from 'react-phone-input-2';
import Timeslots from './timeslots';
import CheckoutDrawer from './checkout-drawer';
import Api_Request from './api/api-request';
import useSound from 'use-sound';
import moment from 'moment';
import warningSound from './assets/sounds/warning.mp3';
import 'react-phone-input-2/lib/style.css';
const api = new Api_Request();

/**
 * 
 * 
 * Checkout Component 
 * 
 */ 
export default function Checkout() {

   const { serviceOne, serviceTwo, dayoff, breakTime } = useContext( HeaderContext );
   const dateFormat = 'M-D-YYYY'; 

   const [ data, setData ] = useState({})
   const [ continueLoading, setContinueLoading ] = useState(false);     /* For continue button loading */
   const [ visible, setVisible ] = useState(false);                     /* For submit drawer opening/closing */
   const [ loading, setLoading ] = useState(false);                     /* for timeslot panel laoding */
   const [ complete, setComplete ] = useState({
      timeslot: false,
      form: false
   });

   const [submitTxt, setSubmitTxt ] = useState({
      txt: 'Submit Now',
      loading : false,
   });

   const [ playWarningSound ] = useSound(warningSound);
   const clientFormRef = useRef(null);

   useEffect(()=>{
      document.body.scrollIntoView({block: "end"});
   },[])
   
   /**
    * 
    * 
    * check weather serviceOne and serviceTwo (home) are selected or empty
    * 
    */ 
   useEffect(()=>{
      if( !serviceOne.time || !serviceTwo.username ) {
         window.location.pathname = '/'
      } else {
         setData({
            services       : serviceOne.title.trim(),
            service_name   : serviceOne.name.trim(),
            barber_name    : serviceTwo.name.trim(),
            barber_username: serviceTwo.username.trim(),
            price          : serviceOne.price.trim(),
            duration       : serviceOne.time,
            time           : '',
            slot           : '',
            date           : moment().format(dateFormat),
            first_name     : '',
            last_name      : '',
            email_address  : '',
            phone_number   : '',
            etag           : '',
            status         : 'pending',
         })
      }

   },[serviceOne, serviceTwo])
  
   /**
    * 
    * Validate fields
    *  
    */ 
   useEffect(()=>{
      setComplete( prevState => {

         if( prevState.timeslot ) {
            if( 
               data.phone_number.trim().length > 7 &&
               validateEmail( data.email_address ) &&
               data.first_name.trim().length > 1 &&
               data.last_name.trim().length > 1 
            ) {
               setComplete( prevState => {
                  prevState.form = true;
                  return {...prevState}
               } )
            } else {
               setComplete( prevState => {
                  prevState.form = false;
                  return {...prevState}
               } )
            }
         } 

         return { ...prevState }
      })

   },[data])
    

   /**
    * 
    * 
    * continue button handler
    * 
    */ 
   const validateEmail = email => {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
   }

   const continueButtonHandler = () => {

      setContinueLoading(true);

      /**
       * 
       * 
       * Prepare etag to avoid posting data at the same time
       * 
       */
      let edata = {
         endpoint: '?etag',
         body: []
      }

      api.post(edata, etagres => {

         if( etagres.code !== 202 ) {

            notification.open({
               message: <h2> Please try again </h2>,
               description: <p> Someone is trying to schedule now. Please try again</p>
            });

            playWarningSound();
            setContinueLoading(false);
            return false;

         } 

         setData( prevState =>{
            prevState.etag = etagres.body
            return { ...prevState }
         } )

         setContinueLoading(false);
         setVisible(true);

      })
      
 

   }

   
   /**
    * 
    * 
    * return checkout markup
    * 
    */
   return (
      <Fragment>
         { serviceTwo.username && serviceOne.time && dayoff  && 
         <div className="frontend-home checkout-page-root addcalenderdate">

            { complete.timeslot && complete.form && 
               <h2 className="use-this-date"> { data.date }
                  <Button disabled={continueLoading} loading={continueLoading} onClick={continueButtonHandler}> 
                     Continue  <ArrowRightOutlined /> 
                  </Button> 
               </h2>
            }

            {/**
             * 
             * 
             * 
             *  Show Head and page title
             * 
             * 
            */}
            <Header/>
            <PageTitle title="Select date" />
      
            <div className="page-container transparent-bg checkout-page">
            {
               !serviceOne.time || !serviceTwo.username ? <Loading/> : 
               <div className="front-end-checkout">
               
                  {/**
                   * 
                   * 
                   * Show appointment calendar
                   * 
                   */}
                  <div>
                     <Calendar 
                        format={ dateFormat }
                        fullscreen={false} 
                        className="checkout-calendar"
                        disabledDate={ value => {
                           // disable previous dates
                           if( value.format(dateFormat) === moment().format(dateFormat) || value.isAfter() ) {
                              //disable weekends   
                              if( serviceTwo.weeklyOff?.includes( value.day() + 1 ) ) return true;
                              
                              if( dayoff && dayoff[serviceTwo.username]?.includes( value.format( dateFormat ) ) ) {
                                 return true;
                              }
                              // //disable global dayoff
                              if( dayoff?.all?.includes( value.format( dateFormat ) ) ) return true;
                              //everyday break time isFULL
                              if( breakTime && breakTime[serviceTwo.username] && breakTime[serviceTwo.username].everyday && breakTime[serviceTwo.username].everyday[0]?.slot.length === 19 ) return true;
                              // disable when current date is true but time slot has passed
                              if( value.format(dateFormat) === moment().format(dateFormat) && currentTime() >= 19) return true;

                           } else {
                              return true
                           }
                        } } 
                        dateFullCellRender={ value => {
                           //disable weekends   
                           if( serviceTwo.weeklyOff?.includes( value.day() + 1 ) ) {
                              return <div className='day-off'>{value.date()}</div>
                           };
                           
                           // disable previous dates
                           if( value.format(dateFormat) === moment().format(dateFormat) || value.isAfter() ) {
                              
                              if( dayoff && dayoff[serviceTwo.username]?.includes( value.format( dateFormat ) ) ) {
                                 return <div className='day-off'>{value.date()}</div>;
                              }
                              // //disable global dayoff
                              if( dayoff?.all?.includes( value.format( dateFormat ) ) ) {
                                 return <div className='day-off'>{value.date()}</div>
                              }
                              //everyday break time isFULL
                              if( breakTime && breakTime[serviceTwo.username] && breakTime[serviceTwo.username].everyday && breakTime[serviceTwo.username].everyday[0]?.slot.length === 19 ) {
                                 return <div className='day-off'>{value.date()}</div>
                              };
                              // disable when current date is true but time slot has passed
                              if( value.format(dateFormat) === moment().format(dateFormat) && currentTime() >= 19) {
                                 return <div className='previous-date'>{value.date()}</div>
                              };

                              return <span className='available'>{value.date()}</span>

                           } else {
                              return <span className='previous-date'>{value.date()}</span>
                           }
                        } }
                        onChange={ value => {
                           const date = value.format( dateFormat );
                           setData( prevState => {
                              return {...prevState, date}
                           } )
                        } }

                     />
                  </div>
                  {/**
                   * 
                   * 
                   * Display Time Slot 
                   * 
                   * 
                   */}
                  <Timeslots 
                     setLoading={ setLoading }
                     loading={ loading }
                     date={ data.date }
                     defaultValue={ data.slot }
                     onRender={ response => {

                        if( response ) {
                           clientFormRef.current.scrollIntoView({behavior: "smooth", block: "start"});
                        }
                        
                        setComplete( prevState => {
                           prevState.timeslot = response
                           return {...prevState}
                        })
                     } }

                     onChange={ev => {
                        clientFormRef.current.scrollIntoView({behavior: "smooth", block: "start"});
                        setData( prevState => {
                           prevState.slot = ev.target.value;
                           prevState.time = slotToTime( ev.target.value )
                           return {...prevState}
                        } )
                     } }
                  />
                  { 
                  /**
                   * 
                   * Show form when date and time is selected
                   * 
                   */ 
                     <div ref={clientFormRef}>
                     {
                        complete.timeslot && 
                        <div className="userInfoForm">
                              <h2> Fill up this form <span style={{color: 'red', fontSize: '30px', verticalAlign: 'middle', lineHeight: '8px'}}>*</span> </h2>
                              <div className="form-grid">
                                 <div className="number-group">                           
                                    <PhoneInput
                                       inputClass="phone_number"
                                       country={'us'}
                                       value={data.phone_number}
                                       enableSearch={true}
                                       countryCodeEditable={ false }
                                       placeholder="Enter phone number with country code"
                                       onChange={ phone_number => setData( prevState => {
                                          prevState.phone_number = phone_number
                                          return {...prevState};
                                       } ) }
                                    />
                                 </div>

                                 <Input 
                                    type="text" 
                                    className="email_address" 
                                    name="email_address" 
                                    placeholder="Email Address" 
                                    value={data.email_address}
                                    onChange={ ev => {
                                       setData( prevState => {
                                          prevState.email_address = ev.target.value
                                          return {...prevState}
                                       } )
                                    } }

                                    onBlur={ ev => {
                                       if( !validateEmail( ev.target.value ) ) {
                                          playWarningSound();
                                          notification.open({
                                             message: <h3> Enter a valid email </h3>,
                                          });
                                       } 
                                    } }

                                 />

                                 <Input 
                                    type="text"  
                                    className="first_name" 
                                    name="first_name" 
                                    placeholder="First Name" 
                                    value={data.first_name}
                                    onChange={ ev => {
                                       setData( prevState => {
                                          prevState.first_name = ev.target.value
                                          return {...prevState}
                                       } )
                                    } }
                                    onBlur={ ev => {
                                       if( ev.target.value.trim().length < 1 ) {
                                          playWarningSound();
                                          notification.open({
                                             message: <h3> Enter first name </h3>,
                                          });
                                       } 
                                    } }

                                 />
                                 <Input 
                                    type="text" 
                                    className="last_name" 
                                    name="last_name" 
                                    placeholder="Last Name" 
                                    value={data.last_name}
                                    onChange={ ev => {
                                       setData( prevState => {
                                          prevState.last_name = ev.target.value
                                          return {...prevState}
                                       } )
                                    } }
                                    onBlur={ ev => {
                                       if( ev.target.value.trim().length < 1 ) {
                                          playWarningSound();
                                          notification.open({
                                             message: <h3> Enter last name </h3>,
                                          });
                                       } 
                                    } }
                                 />
                              </div>
                        </div>
                     }
                  </div>
                  }
                  
               </div>
            }
               
            </div>
            
         </div>
         }
         <Drawer
            title="Fill up the form"
            placement="right"
            closable={true}
            className="checkout-drawer"
            closeIcon={<span type="button" className='ant-btn ant-btn-danger ant-btn-lg'> Go Back </span>}
            width={320}
            forceRender
            onClose={()=> setVisible(false) }
            visible={visible}
         >
            <CheckoutDrawer submitTxt={submitTxt} setSubmitTxt={setSubmitTxt} submitData={data} setVisible={setVisible}/>
         </Drawer>

      </Fragment>
   )
}
