import { useEffect, useContext } from 'react';
import { HeaderContext } from './api/api-context';
import { Header, PageTitle, Loading } from './utils';
import BarbersContainer from './components/barber-container';

/**
 * Barber main Components 
 */ 
export default function Barbers() {

   const {setServiceTwo, barberData, serviceOne, priceVariation, serBarberData} = useContext( HeaderContext );

   useEffect(()=>{

      if( !serviceOne || serviceOne.length === 0 ) {
         window.location.pathname = '/'
      }

      document.body.scrollIntoView({block: "end"});
   },[])

   /**
    * 
    * next button handler 
    */  
   const NextItem = (type, data ) => {
      setServiceTwo(data)
   }
   /**
    * return barber JSX markup
    */ 
   return (
      <div className="frontend-home barber-page">
         <Header/>
         <PageTitle title="Choose stylist" />
         <div className="page-container">
            {  
               barberData.length < 1 ? <Loading/> : 
               <BarbersContainer
                  priceVariation={priceVariation}
                  serviceOne={serviceOne}
                  data={ barberData } 
                  setData={serBarberData}
                  nextitem={ NextItem }
               />
            }
         </div>
      </div>
   )
}
