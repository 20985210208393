import { useEffect, useState, useContext } from 'react';
import { Header, PageTitle, Loading, Haircuts } from './utils';
import { HeaderContext } from './api/api-context';

/**
 * 
 * 
 * Main Home component where all the services will appear 
 * 
 */ 
export default function Home() {

   const [isLoaded, setIsLoaded ] = useState(false);
   const {setServiceOne, serviceData, barberData } = useContext( HeaderContext );

   useEffect(()=>{
      if( serviceData.length && barberData.length ) {
         setIsLoaded(true);
      } else {
         setIsLoaded(false)
      }
   },[serviceData, barberData])


   /*---------------------------
   next button handler
   ----------------------------*/  
   const NextItem = (type, data ) => {
      setServiceOne(data)
   }

   return (
      <div className="frontend-home">
         <Header/>
         <PageTitle title="select service" />
      
         <div className="page-container">
             { !isLoaded ?  <Loading/> : <Haircuts  data={ serviceData } nextitem={ NextItem }/>}
         </div>

      </div>
   )
}
