import { useContext } from 'react';
import { Button, Select, Radio } from 'antd';
import { HeaderContext } from '../../api/api-context';
import Context from './context';

/**
 * 
 * 
 * Add / Edit drawer component
 * 
 * 
 * */ 
export default function AddEdit({ children }) {


    /**
    * 
    * Context API 
    * 
    */ 
    const { allBarber } = useContext(HeaderContext);
    const { action, breakTimeState, setBreakTimeState, disableSlot, onSubmitHandler }   = useContext(Context)
   
   
    


    return (
        <div className="break-time-layout">
            {/**
             * 
             * 
             * Select a barber for break time 
             * 
             * 
             */}
            <div className="break-time-layout__item">
                <label className="label"> Select Barber </label>
                <div className="content">
                    <Select 
                        disabled={ action !== 'add' } 
                        size="large" 
                        style={{width: '100%'}} 
                        className="filter-barber" 
                        value={ breakTimeState?.type } 
                        onSelect={ type => {
                            setBreakTimeState(prevState =>  {
                                prevState.type = type;
                                prevState.mode = 'custom'
                                prevState.time = []
                                return {...prevState}
                            })
                        } }
                    >
                        {Object.entries( allBarber ).map(([username, item]) => <Select.Option key={username} value={username}>{item.name}</Select.Option>)}
                    </Select>
                </div>
            </div>
               
            {/**
             * 
             * 
             * Break time type 
             * 
             * 
             */}
            <div className="break-time-layout__item">
                <label className="label"> Select Break Type </label>
                <div className="content">
                    <Radio.Group 
                        size="large" 
                        buttonStyle="solid"
                        disabled={ action !== 'add' } 
                        value={ breakTimeState.mode } 
                        onChange={ mode => {
                        setBreakTimeState( prevState => {
                            return {
                                ...prevState, 
                                mode : mode.target.value,
                                time : mode.target.value !== 'everyday' ? [] : [{
                                    note: '',
                                    slot: []
                                }]
                            }
                        });
                        }} 
                    >
                        <Radio.Button value="custom">Custom</Radio.Button>
                        <Radio.Button value="everyday">Everyday</Radio.Button>
                        <Radio.Button value="weekly">Weekly</Radio.Button>
                    </Radio.Group>
                </div>
            </div>
                
            {children}

            {
                action === 'add' && 
                <h3 style={{color: 'red'}}>
                    Be careful!!. Old <strong>{ breakTimeState?.mode }</strong> break time of <strong>{ allBarber[breakTimeState.type].name }</strong> will be overwritten.  
                </h3>
            }

            {/**
             * 
             * 
             * Submit Now Button 
             * 
             * 
             */
            
            breakTimeState.time.length > 0 &&  <Button className="submit-btn" disabled={disableSlot} loading={disableSlot} size="large" onClick={onSubmitHandler} type="primary" block>Submit Now</Button>

            }
                  
               
        </div>
    )
}