import { useEffect, useState } from 'react';
import { Header, nextTimeSlot, time24 } from './utils';
import { GoogleOutlined } from '@ant-design/icons';
import thankYou from './assets/images/thank-you.jpg';

/**
 * 
 * 
 * ThankYou page main component
 * @function ThankYou
 * 
 */ 
export default function ThankYou() {

    const [calUrl, setCalUrl] = useState('#');   /* store calendar url */
    
    /**
     * 
     * 
     * Format Time for google calendar button 
     * 
     */ 
    const formatTime = date => {
        let isoString = date.toISOString();
            isoString = isoString.replaceAll(':', '');
            isoString = isoString.replaceAll('-', '');
        return isoString;
    }

    /**
     * 
     * 
     * Serialize google calendar url
     * 
     */ 
    const serialize = (obj) => {
        let str = [];
        for (let p in obj) {
          if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
          }
        }
        return str.join('&');
    }
    /**
     * 
     * 
     * Prepare google calendar url
     * 
     */
    const googleURL = ( obj ) => {

        let startDate = formatTime(new Date(obj.start))
        let endDate = formatTime(new Date(obj.end))

        let googleArgs = {
            'text'      : (obj.title || ''),
            'dates'     : startDate + '/' + endDate,
            'location'  : (obj.location || ''),
            'details'   : (obj.description || ''),
            'ctz'       : (obj.timezone || ''),
            'locale'    : (obj.locale || ''),
            'sprop'     : ''
        };

        return  'https://www.google.com/calendar/render?action=TEMPLATE&' + serialize(googleArgs);
    }

    /**
     * 
     * 
     * format ISO time
     * 
     */
    const formatIOSTime = (date, slot) => {
        // change date format for google calendar
        let time = time24(slot).toString();
        let dd = date.split('-');
            dd = `${dd[2]}-${dd[0]}-${dd[1]}`;
        
        // change time minitue format 
        if( time.length === 2 ) {
            time += ':00';
        }

        // change time minitue format
        if( time.length === 4) {
            time = time.replace('.', ':') + '0';
        }
        
        // change time mili seconds format
        time = time + ':00';

        // make full date
        let fullDate = `${dd} ${time}`;
        let datetime = new Date(fullDate);

         // In case its IOS, parse the fulldate parts and re-create the date object.
         if(Number.isNaN(datetime.getMonth())) {
            let arr = fullDate.split(/[- :]/);
            datetime = new Date(arr[0], arr[1]-1, arr[2], arr[3], arr[4], arr[5]);
        }

        return datetime

    }

    useEffect(()=>{
        const checkout = window.history?.state?.state;

        const startTime = formatIOSTime(checkout.date , checkout.slot);
        const nextSlot = nextTimeSlot(checkout.slot, checkout.duration);
        const EndTime = formatIOSTime(checkout.date , nextSlot);
       
        if( checkout && checkout.date && checkout.time ) {
            const eventObj = {
                start: startTime,
                end: EndTime,
                timezone: '(GMT-04:00) Eastern Time - New York',
                title: 'Appointment with Big Apple Barbers',
                description:`You schedule your appointment with <strong>${checkout.barber_name}</strong> for a <strong>${checkout.services}</strong> . Please arrive 10 minutes prior to your appointment Thank you. <ul> <li> Price : ${checkout.price} </li> <li> Service : ${checkout.services} </li><li> Duration : ${checkout.duration} mins </li><li> Stylist : ${checkout.barber_name} </li></ul>`,
                location: 'Big Apple Barbers, 422 E 14th St, New York, NY 10009, USA',
                isAllDay    : false,
            }
            const url = googleURL(eventObj);
            setCalUrl(url);
        }

    },[])

   return (
      <div className="frontend-home">
         <Header/>

         <div className="page-container thankyou-container">
            <div className="thankyou-container">
                <img src={thankYou}/>
                <h2>To reschedule or cancel your appoinment, visit your email!</h2>
                <a href="https://bigapplebarbershop.com" className="finish-appointment"> Finish</a> <br/>
                { calUrl !== '#' ?  <a href={calUrl} target="_blank" className="google-calendar"> <GoogleOutlined/> Add to Google Calendar </a> : null }         

            </div>
         </div>

      </div>
   )
}
