import { useState, useEffect, useContext } from 'react';
import { Link } from "react-router-dom";
import { Table, Button, Select, notification  } from 'antd';
import Api_Request from './../api/api-request';
import { HeaderContext } from '../api/api-context';

const api = new Api_Request();

const columns = [
   {
     title: 'ID',
     dataIndex: 'id',
   },
   {
     title: 'Email/Phone',
     dataIndex: 'info',
   },
   {
     title: 'Date',
     dataIndex: 'date',
   },
 ];

/*============================
React Component Start
=============================*/ 

const ClientInfo = () => {

   const [selectedRowKeys, setSelectedRowKeys ] = useState([]);
   const [data, setData] = useState([])
   const [type, setType ] = useState('email_tb');
   const [action, setAction ] = useState('promotion');
   const {clientInfoEmail, clientInfoNumber, setClientInfoNumber, setClientInfoEmail} = useContext(HeaderContext);

   const fetChUserData = (t) => {

      if( t === 'email_tb' && clientInfoEmail.length) {
         setData(clientInfoEmail);
         return false;
      }

      if( t === 'number_tb' && clientInfoNumber.length ) {
         setData(clientInfoNumber);
         return false;
      }

      api.get(`/?getuserinfo=${t}`, res =>{
         if( res.code === 200 ) {
            const dd = [];
            for (let i = 0; i < res.body.length; i++) {
               

               dd.push({
                 key: res.body[i].id,
                 id: res.body[i].id,
                 info: res.body[i].email ? <p className="userEmail_add">{res.body[i].email} <span> { res.body[i].last  } </span> </p> : <p className="userEmail_add">{res.body[i].number} <span> { res.body[i].last  }</span></p>,
                 date: res.body[i].date.split('00:00:0')[0],
               });
           
             }
     
             setData(dd);

             if( t === 'email_tb' ) {
               setClientInfoEmail(dd);
               return false;
             }

             if( t === 'number_tb' ) {
               setClientInfoNumber(dd);
               return false;
             }

         }
      })
   }

   useEffect(() => {
      fetChUserData(type)
   }, [type])


   
   const actionApply = () => {
      if( action === 'delete' ) {
         const args = {
            endpoint: `?deleteuserinfo`,
            body: {
               table: type,
               rows: selectedRowKeys
            }
          }
       
          api.delete(args, res => {
           if(res.code === 202) {
             notification.open({
                message: <h3>Delete Successful</h3>,
             });
             fetChUserData(type)
           }
         })
      }

   }


   const onSelectChange = selectedRowKeys => {
      setSelectedRowKeys(selectedRowKeys)
    };

   const rowSelection = {
      selectedRowKeys,
      onChange: onSelectChange,
    };

   return (
      <div className="admin-user-info">
           <div className="admin-table-header">
           <div className="action-header">
               <Select defaultValue={type} onChange={val => setType(val) } style={{ width: 120 }} >
                  <Select.Option value="email_tb">Email</Select.Option>
                  <Select.Option value="number_tb">Number</Select.Option>
               </Select>
               <p>Selected {selectedRowKeys.length}</p>
           </div>
            
           
               {selectedRowKeys.length ? 
                  <div className="action-header">   
                     <Select defaultValue={action} onChange={val => setAction(val) } style={{ width: 120 }} >
                        <Select.Option value="promotion">Promotion</Select.Option>
                        <Select.Option value="delete">Delete</Select.Option>
                     </Select>
                     {action === 'promotion' ?
                        <Link to={{
                              pathname: "/promotion",
                              state: { keys: selectedRowKeys, type: type }
                            }}> 
                           <Button type="primary">Continue</Button> 
                        </Link>
                     :  <Button type="primary" onClick={actionApply}> Apply </Button>
                     }
                    
                  </div>
               : null
               }
           </div>
          <Table rowSelection={rowSelection} columns={columns} dataSource={data} />
      </div>
   )
}


export {ClientInfo};