import {useState, useEffect, useContext} from 'react';
import { Drawer, Button, Form, Input, Radio, notification  } from 'antd';
import { getCookie } from './helper';
import Api_Request from './../api/api-request';
import useSound from 'use-sound';
import warningSound from './../assets/sounds/warning.mp3';

import {Loading} from './../utils';
import {HeaderContext} from './../api/api-context';

const api = new Api_Request();

const Users = () => {

   const [loginLoading, setLoginLoading] = useState(false);
   const [pageLoaded, setPageLoaded]   = useState(false);
   const [visible, setVisible] = useState(false);
   const [playWarningSound] = useSound(warningSound);
   const { adminUsers, setAdminUsers } = useContext( HeaderContext );
   
   useEffect(()=>{
      const userName = getCookie('username');
      const sid = getCookie('sid');
   
      if( userName && sid ) {
         const args = {
            endpoint: '?userlist',
            body: {
               username: JSON.parse(userName),
               sid : JSON.parse(sid)
            }
         }
         if( !adminUsers.length ) {
            api.post(args, res => {
               if( res.code === 200 && res.body.content !== null) {
                  setAdminUsers(res.body)
                  setPageLoaded(true)
               }
            })
         } else {
            setPageLoaded(true)
         }
      }


   },[])

   /*--------------------------
   Submit the form
   ----------------------------*/ 
   const onFinish = (value) => {
      setLoginLoading(true);
      
      const args = {
         endpoint: '/?users',
         body: {
            username: value.username.replaceAll(' ', '_'),
            phone: value.phone,
            password: value.password,
            type: value.type,
            data: {
               name: value.name,
               email: value.email,
               created_by: JSON.parse(getCookie('username'))
            }
         }
      }

      api.post(args, res => {
         if( res.code === 202 ) {
            const aa = [...adminUsers, res.body];
            setAdminUsers(aa);
            setVisible(false)
         }
         else {
            notification.open({
               message: <h3> Can not create this user </h3>,
               description: <p>Phone or username already taken</p>
             });
            playWarningSound();
         }
         setLoginLoading(false)
      })
   }

  /*--------------------------
   Delete Users form databse
   ----------------------------*/ 
   const deleteUserItem = (username, phone, key) => {
      let args = {
         endpoint: `/?users&username=${username}&phone=${phone}`,
         body: ''
      }
      api.delete(args, res => {
         if( res.code === 200 ) {
            const aa  = [...adminUsers];
            aa.splice(key, 1);
            setAdminUsers(aa);
         }
      })
   }

   const renderUser = (key, item) => {
      const data = item.data;
      return (
         <div className="user-data-item" key={key}>
               <h2 className="user-name">{data.name}</h2>
               <p className="user-info"><strong>Username:</strong> <span>{item.username}</span> </p>
               <p className="user-info"><strong>Phone:</strong> <span>{item.phone}</span> </p>
               <p className="user-info"><strong>Password:</strong> <span>{item.password}</span> </p>
               <p className="user-info"><strong>Type:</strong> <span>{item.type}</span> </p>
               <p className="user-info"><strong>Email:</strong> <span>{data.email}</span> </p>
               {key !== 0 ?  
                  <p>  <Button loading={loginLoading} onClick={() => deleteUserItem(item.username, item.phone, key)} danger  type="primary" >Delete </Button> </p>
               : null
            }
         </div>
      )
   }

   return (
      <>
      { !pageLoaded ? <Loading/> : 
      <div className="admin-user-container">
         <div className="user-showcase">
            {adminUsers.map((item, key) => renderUser(key, item))}
         </div>
         <div className="user-footer">
            <Button size="large" type="primary" onClick={() => setVisible(true)}>Add a New User</Button>
         </div>

         <Drawer
            title="Add a New User"
            className="admin-service-drawer"
            placement="right"
            closable={true}
            onClose={() => setVisible(false)}
            visible={visible}
         >

            <Form
               name="service-form"
               className="service-form"
               onFinish={onFinish}
            >
            <Form.Item name="name" rules={[{ required: true, message: 'Enter Full Name' }]} >
               <Input placeholder="Full Name" />
            </Form.Item>

            <Form.Item name="username" rules={[{ required: true, message: 'Enter username' }]} >
               <Input placeholder="username without any space" />
            </Form.Item>

            <Form.Item name="phone" rules={[{ required: true, message: 'Enter Phone Number' }]} >
               <Input type="number" placeholder="Enter Phone Number" />
            </Form.Item>

            <Form.Item name="password" rules={[{ required: true, message: 'Enter password' }]} >
               <Input type="text" placeholder="Enter password" />
            </Form.Item>

            <Form.Item  name="email" rules={[{ required: true, message: 'Enter email address' }]} >
               <Input placeholder="Enter email address" />
            </Form.Item>

            <Form.Item name="type" rules={[{ required: true, message: 'Select user type' }]}>
               <Radio.Group
                  options={[
                     { label: 'Admin', value: 'admin' },
                     { label: 'Stylist', value: 'stylist' },
                   ]}
                  optionType="button"
               />
            </Form.Item>


            <Form.Item>
               <Button size="large" loading={loginLoading}  type="primary" htmlType="submit" className="login-form-button">
                   Create Now
               </Button>
            </Form.Item>

            </Form>


         </Drawer>

      </div> }
      </>
   )
}


export {Users};