import { Button, Form, Switch, Input, notification  } from 'antd';
import { useEffect, useState, useContext } from 'react';

import {Loading} from './../../utils';
import { HeaderContext } from '../../api/api-context';
import Api_Request from '../../api/api-request';

const api = new Api_Request();

export default function VariationPrice() {
    
    const { priceVariation, setPriceVariation } = useContext( HeaderContext );
    const [loading, setLoading] = useState(false)
    const [pageLoaded, setPageLoaded] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        if( !priceVariation ) {
            api.get('/?variationprice', res => {
                setPageLoaded(true)
                if( res.code === 200 && res.body.content !== null) {
                    setPriceVariation(res.body.content);
                    form.setFieldsValue(res.body.content);
                }
            })
        } else {
            setPageLoaded(true)
            form.setFieldsValue( priceVariation )
        }
    }, [])

    const onFinish = (values) => {
        setLoading(true)

        const data = {
            endpoint: `?variationprice`,
            body: values
        }
      
        api.post(data, res =>{
            if( res.code === 202 ) {
                notification.open({
                    message: <h3>Successful</h3>,
                });
                setPriceVariation(res.body.content)
            }
            setLoading(false)
        })
    }

    return (
    <> {!pageLoaded ?  <Loading/> : 
        <div className="admin-variation-price">
            <h2> VIP Pricing </h2>
            <hr/> <br/>
            <Form
               name="price-form"
               className="price-form"
               form={form}
               initialValues={{ remember: false }}
               onFinish={onFinish}
               layout="vertical"
            >
                <Form.Item label="Enable Individual Default Price"  name="enable_individual_default_price" valuePropName="checked">
                    <Switch/>
                </Form.Item>

                <Form.Item label="Enable VIP Price"  name="enable_vip_price" valuePropName="checked">
                    <Switch/>
                </Form.Item>
                 
                <Form.Item label="VIP Code"  name="vip_code" >
                    <Input size='large'/>
                </Form.Item>

                <Form.Item>
                    <Button size="large" loading={loading}  type="primary" htmlType="submit" className="login-form-button">
                        Submit Now
                    </Button>
                </Form.Item>
            </Form>
        </div>
        }
    </>
    )
}