
class Api_Request {

   endpoint = 'https://www.bigapplebarbershop.com/api/index.php'
   apiRoot  = 'https://www.bigapplebarbershop.com/api/';
   // endpoint = 'http://salon-api.test/index.php'
   // apiRoot  = 'http://salon-api.test/';

   /*
      ---------------------------
      get method to fetch api data
      ---------------------------
   */ 
   get = async (props, fn) => {   
     await fetch(`${this.endpoint}${props}`)
         .then(response => response.json())
         .then(data => fn( data ))
         .catch( error =>{
             console.log(error);
         });
   }


   /*
      ---------------------------------
      put method to check or get data
      --------------------------------
   */ 
   put = (props, fn) => {
      
      let arg = {
         method : 'PUT',
         body   : JSON.stringify(props.body),
         headers: {
             'Content-Type' : 'application/json'
         }
      }
      
      fetch(`${this.endpoint}/${props.endpoint}`, arg)
      .then(response => response.json())
      .then(data => fn(data))
      .catch( error =>{
          console.log(error);
      });
   }


   /*
      ---------------------------------
      Send SMS
      --------------------------------
   */ 
   send_SMS = async (body, fn) => {      
      await fetch(`${this.apiRoot}twilio.php?p=${body.p}&b=${body.b}`)
      .then(response => response.json())
      .then(data => fn(data))
      .catch( error =>{
          console.log(error);
      });
   }

   /*
      ---------------------------------
      Yelp Review
      --------------------------------
   */ 
   yelp_reviews = async (fn) => {      
      await fetch(`${this.endpoint}?yelpreview`)
      .then(response => response.json())
      .then(data => fn(data))
      .catch( error =>{
          console.log(error);
      });
   }


   /*
      ---------------------------------
      put method to create resources
      --------------------------------
   */ 
   post = (props, fn) => {      
      let arg = {
         method : 'POST',
         body   : JSON.stringify(props.body),
         headers: {
             'Content-Type' : 'application/json'
         }
      }
      
      fetch(`${this.endpoint}/${props.endpoint}`, arg)
      .then(response => response.json())
      .then(data => fn(data))
      .catch( error =>{
          console.log(error);
      });
   }

     /*
      ---------------------------------
      upload image from frontend
      --------------------------------
   */ 
      upload = (props, fn) => {      
         let arg = {
            method : 'post',
            body   : JSON.stringify(props.file),
         }
         
         fetch(`${this.apiRoot}upload.php?action=${props.action}`, arg)
         .then(response => response.json())
         .then(data => fn(data))
         .catch( error =>{
            console.log(error);
         });
      }

   /*
      ---------------------------------
      delete method to remove resources
      --------------------------------
   */ 
      delete = (props, fn) => {      
         let arg = {
            method : 'DELETE',
            body   : JSON.stringify(props.body),
            headers: {
                'Content-Type' : 'application/json'
            }
         }
         
         fetch(`${this.endpoint}/${props.endpoint}`, arg)
         .then(response => response.json())
         .then(data => fn(data))
         .catch( error =>{
             console.log(error);
         });
      }

} // end of class


export default Api_Request;