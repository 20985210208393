import {useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { HeaderContext } from './../api/api-context';
import { Button, Collapse, Select, Modal, notification, Calendar} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import Api_Request from '../api/api-request';
import { Loading, ResponseAlert, slotToTime, makeDate } from '../utils';
import useSound from 'use-sound';
import warningSound from './../assets/sounds/warning.mp3';

const api = new Api_Request();

const d = new Date()
const currentDate = (d.getMonth() + 1) + '-' + d.getDate() + '-' + d.getFullYear();


/*-------------------------
React Component
---------------------------*/ 
const Dashboard = () => {

   const [ activeStylist, setActiveStylist ] = useState([]);
   const [ appointments, setAppointments ] = useState([]);
   const [ load, setLoad ] = useState(false);
   const [ barber, setBarber ] = useState('');
   const [ date, setDate ] = useState(currentDate);
   const [ booked, setBooked ] = useState([]);
   const [ playWarningSound ] = useSound(warningSound);
   const [ btnLoad, setBtnLoad ] = useState(false);
   const [ pageLoaded, setPageLoaded ] = useState(false);
   const [ breakSlot, setBreakSlot ] = useState({});
   const { user, breakTime } = useContext( HeaderContext );

   useEffect(()=>{
      // fetch all active barber from database
      api.get('/?activestylist', res => {
         if( res.code === 200 ) {
            setActiveStylist( res.body )
            setPageLoaded(true);
            callApi( user.username , date)
         }
      })

   },[]) 

   const createBreakTimeAppointment =(breaks, date)=>{

      const currentWeek = makeDate(date).day();

      const update = (slots, note) => {

         slots.map( slot => {
            setBreakSlot( prevState => {
               prevState[slot] = {
                  slot: slot,
                  time: slotToTime(slot),
                  first_name: note,
                  disabled: true,
               }
               return {...prevState}
            })
         })
      
      }

      if( breaks.everyday ) {
         update( breaks.everyday[0].slot, breaks.everyday[0].note )
      }

      if( breaks.weekly ) {
         breaks.weekly.map( item => {
            if( item.date === currentWeek) {
               update( item.slot, item.note )
            }
         })
      }

      if( breaks.custom ) {
         breaks.custom.map( item => {
            if( item.date === date) {
               update( item.slot, item.note )
            }
         })
      }      
   }

   useEffect(()=>{
      setBreakSlot({});

      if( breakTime && barber ) {
         if( breakTime['all'] ) {
            createBreakTimeAppointment(breakTime['all'], date);
         }
   
         if( breakTime[barber] ) {
            createBreakTimeAppointment(breakTime[barber], date);
         }
      }


   }, [date, barber, breakTime])

   const callApi = (ur, dt) => {
      setLoad(true)
      const args = {
         endpoint: '?getappointment',
         body: {
            username: ur,
            date: dt
         }
      }
      api.post(args, res2 => {
         
         // set barber username when it matches with active barber list fetching from database
         setBarber(ur)

         if( res2.code === 200 ) {
            setBooked(res2.body.bookeddates)
            setAppointments(res2.body.data );
         } else {
            setBooked(res2.body)
            setAppointments([])
         }
         setTimeout(()=> setLoad(false), 200)
      })

   }



/*-------------------------
On Barber Change
---------------------------*/ 
const onBarberChange = value => {
   setBarber(value)
   callApi(value, date)
}

/*-------------------------
On date Change
---------------------------*/ 
const onDateChange = value => {
   let dt = value.format("M-D-Y");
   setDate(dt);
   callApi(barber, dt)
}


/*-------------------------
on Cancel appointment
---------------------------*/ 
const onCancelAppointment = (token, phone, slot) => {

   setBtnLoad(true);
   let args = {
      endpoint: `/?delete=${token}&phone=${phone}`,
      body: ''
   }

   api.delete(args, res => {
      if( res.code === 200 ) {
          Modal.confirm({
            title: 'Delete Successful',
            icon: <ExclamationCircleOutlined />,
            content: <span className="thank-you-msg"> Successfully Deleted the appointment </span>,
            cancelText: 'Close',
          });
          callApi(barber, date)
      } 
      else {
         notification.open({
            message: <h4> Something went wrong! </h4>,
            description: <p> Could not delete your appointment. Please try again </p>
          });
          playWarningSound();
      }


      setBtnLoad(false);
   })
}


/*-------------------------
Render daily appointments
---------------------------*/ 

let slots1 = Object.keys(appointments);
let slots2 = Object.keys(breakSlot);
let slots = Array.from( new Set([...slots1, ...slots2]) )

slots.sort((a, b) => {
   a = parseInt(a.split('_')[1]);
   b = parseInt(b.split('_')[1]);
   return a -b;
})


const renderSlot = (slot, key) => {
   let appoin = {...breakSlot, ...appointments}
   let data = appoin[slot];

   function HeaderMarkup() {
      return (
         <span>
            {data.time} : {data.first_name}
            { data.vip_user && data.vip_user === 'yes' && (
               <span className='vip-label '>VIP</span>
            )}
         </span>
      )
   }
   
   return(
      <Collapse.Panel collapsible={data.disabled ? 'disabled' : 'header'} header={<HeaderMarkup/>} key={key}>
        <table>
           <tbody>
              <tr>
                 <td> <strong>Name:</strong> { data.first_name + ' ' + data.last_name}</td>
              </tr>
              <tr>
                 <td> <strong>Service:</strong> {data.services}</td>
              </tr>
              <tr>
                 <td> <strong>Duration:</strong> {data.duration} mins</td>
              </tr>
              <tr>
                 <td> <strong>Price:</strong> {data.price}</td>
              </tr>
              <tr>
                 <td> <strong>Date:</strong> {data.date} </td>
              </tr>

              <tr>
                 <td> <strong>Stylist:</strong> {data.barber_name} </td>
              </tr>

               { user.type === 'admin' && (
                 <>
                  <tr>
                     <td> <strong>Phone:</strong> <a href={`tel:${data.phone_number}`}> +{data.phone_number} </a>  </td>
                  </tr>
                  <tr>
                     <td> <strong>Email:</strong> {data.email_address} </td>
                  </tr>
                  <tr>
                     <td> <strong>Token:</strong> {data.token} </td>
                  </tr>
                 </>
               ) }
              

              <tr>
                 <td> 
                     {
                        user.type === 'admin' && (
                           <>
                              <Link to={`/admin-reschedule?p=${data.phone_number}&t=${data.token}&origin=admin`}> <Button type="primary" disabled={btnLoad} loading={btnLoad}>Reschedule</Button>  </Link>
                              <Button type="primary" disabled={btnLoad} loading={btnLoad} onClick={()=> onCancelAppointment(data.token, data.phone_number, slot)} danger>Cancel</Button> 
                           </>
                        )
                     }
                     <Link to={{
                        pathname: `/photo-capture`, 
                        state: data
                        }}> <Button disabled={btnLoad} loading={btnLoad}>Add</Button>  
                     </Link>
                     <Link to={{
                        pathname: `/see-photo`, 
                        state: data.phone_number
                        }}> <Button disabled={btnLoad} loading={btnLoad}>See</Button>  
                     </Link>
                  </td>
              </tr>

           </tbody>
        </table>
      </Collapse.Panel>

   )
}

   return (
      <> {!pageLoaded ? <Loading/>  : 
      <div className="admin-appointment-container">

         <div className="appointment-filter-option"> 
            <h4>Filter Appointments</h4>
            <div className="filter-container">
               {user.type === 'admin' ? 
               <Select className="filter-by-stylist" placeholder="Select a stylist/barber" onChange={onBarberChange} value={barber ? barber : null}>
                  {activeStylist?.map((item, key) => <Select.Option key={key} value={item}> {item} </Select.Option>)}
               </Select>
               : null}

               <Calendar mode="month" format="M-D-Y" onSelect={onDateChange} className="filter-by-date" fullscreen={false}

               dateCellRender={current => {
                     let classes = 'inactive';
                     const cd = current.format("M-D-Y");
                     if (booked.indexOf(cd) !== -1) classes = 'active-date'
                     return (
                        <div className="ant-picker-cell-inner" className={classes}>
                           {current.date()}
                        </div>
                     );
                  }}

                  disabledDate={ value => {
                     if( user.type === 'admin' ) return false;
                     let ddate = value.format("M-D-Y");
                     if( ddate !== currentDate) {
                        return value.isBefore();
                     }
                  } }

               />

            </div>
         </div>
         <div className="appointment-content-panel">
            {load ? <Loading/> : 
               <>
                  { !Object.keys(appointments).length ? ResponseAlert({code:204, message: "No Appointments"}) : 
                  <Collapse accordion className='dashboard-appointments'>
                     {slots.map((item, key) => renderSlot(item, key))}
                  </Collapse>
                  }
               </>
            }
         </div>
      </div>  }
      </>
   )
}

export {Dashboard};