import { useState, useEffect } from 'react';
import {useLocation} from 'react-router-dom';
import Api_Request from './../api/api-request';
import { Loading, ResponseAlert } from '../utils';
import { Modal } from 'antd';

const api = new Api_Request();

const SeePhoto = () => {
    const {state} = useLocation();
    const [data, setData] = useState([]);
    const [c, setC] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);


    useEffect(()=>{
        api.get(`/?clientprofilewithphone=${state}`, res =>{
            if( res.code === 200 ) {
                setData(res.body);
                setIsLoaded(true)
            }
            
         })
    },[]);

    if( !state ) {
        window.location.href = 'https://bigapplebarbershop.com/';
        return false;
    }

    // show photo in bigger size
    const showModal = (i) => {
        setC(i);
        setIsModalVisible(true);
    };

    return (
        <>
        { !isLoaded ? <Loading/> :
        <div className="see-client-photos">
            { !data.length ? <ResponseAlert message="no content" code="204"/> : 
            <> 
                {data.map(( item, key ) => <div key={key}>
                    <div>
                        <h1> {item.date} </h1>
                        <p> {item.des} </p>
               
                        <div className="preview-images">
                            {item.photos?.map((p, k) => <img onClick={()=>showModal(p)} key={k+ key} src={p}/>)}
                        </div>
                        <hr/>
                    </div>
                </div>)}

                <Modal 
                    visible={isModalVisible}
                    className="ashraf-see-photos"
                    onOk={()=> setIsModalVisible(false)} 
                    onCancel={()=> setIsModalVisible(false)}>
                    <img src={c}/>
                </Modal>
            </>
            }
        </div>   
         }
        </>
    );

}

export { SeePhoto }