import { React, useState, useEffect, Fragment } from 'react';
import { Route, Switch, useLocation, useHistory } from 'react-router-dom';
import { HeaderContext } from './api/api-context';
import { notification } from 'antd';

import Home from './home';
import ReSchedule from './reschedule';
import Reviews from './reviews';
import Barbers from './barbers';
import Checkout from './checkout';
import ThankYou from './thank-you';

import useSound from 'use-sound';
import warningSound from './assets/sounds/warning.mp3';
import {Login} from './admin/login';
import {setCookie, getCookie} from './admin/helper';

import { DashboardHeader } from './admin/header';
import {Dashboard} from './admin/dashboard';
import {AdminServices} from './admin/admin-services';
import {Users} from './admin/users';
import {Barbers as AdminBarber} from './admin/admin-barbers';
import {YearlyDayOff} from './admin/yearly-dayoff';
import {Individual_dayoff} from './admin/individual-dayoff';
import {ClientInfo} from './admin/client-info';
import {Promotion} from './admin/promotion';
import {ClientDatabase} from './admin/client-database';
import {PhotoCapture} from './admin/photocapture';
import {SeePhoto} from './admin/see-photo';
import BreakTime from './admin/break-time';
import DayoffPage from './admin/dayoff';
import VariationPrice from './admin/variation-price';

import Api_Request from './api/api-request';
import 'antd/dist/antd.css';
import './assets/sass/master.scss';

const api = new Api_Request();
const dateFormat = 'M-D-YYYY'; 
/**
 *  
 * 
 * App Component
 * 
 */
const App = () => {

    const location = useLocation()
    const history = useHistory();

    const [dayoff, setDayoff] = useState(null);               /* state for dayoff management */
    const [breakTime, setBreakTime] = useState(null)        /* state for break time management */
    const [serviceData, setServiceData ] = useState([]);
    const [barberData, serBarberData] = useState([]); 
    const [serviceOne, setServiceOne] = useState([]); 
    const [serviceTwo, setServiceTwo] = useState([]); 
    const [loginLoadings, setLoginLoadings] = useState(false);
    const [loginForm, setLoginForm] = useState(false);
    const [user, setUser] = useState(false);
    const [adminUsers, setAdminUsers] = useState([]);
    const [playWarningSound] = useSound(warningSound);
    const [clientInfoEmail, setClientInfoEmail] = useState([]);
    const [clientInfoNumber, setClientInfoNumber] = useState([]);
    const [priceVariation, setPriceVariation] = useState(null);

    const [ allBarber, setAllBarber ] = useState({                 
      all: {
        name: 'All - Official',
        weeklyOff: [],
      }
    });

    const [ timeslot, setTimeSlot ] = useState({
      30 : {},
      60 : {}
    });

    useEffect(()=>{
      
      
      // fetch dayoff
      if( dayoff === null ) {
        api.get('/?dayoff', res => {
          if( res.code === 200 && res.body !== null) setDayoff(res.body);
        })
      }

      // fetch break tiem data
      if( breakTime === null ) {
        api.get('/?break-time', res => {
          if( res.code === 200 && res.body !== null) setBreakTime(res.body);
        })
      }
      
      // fetch variation price
      if( priceVariation === null ) {
        api.get('/?variationprice', res => {
          if( res.code === 200 && res.body !== null) setPriceVariation(res.body.content);
        })
      }

      // get barber data 
      if( !barberData.length ) { // check if data already fetched
        api.get('/?barbers', res => {
           if( res.code === 200 && res.body.content !== null) {
              serBarberData(res.body.content);
              setAllBarber( prevState => {
                res.body.content.map( item => {
                    prevState[item.username] = {
                      name: item.name,
                      weeklyOff: item.weeklyOff
                    }
                });
                return {...prevState}
              })
           }
        })
      }

      // get services 
      if( !serviceData.length ) {
        api.get('?services', res => {
          if( res.code === 200 && res.body.content !== null) {
             setServiceData(res.body.content)
          }
        })
      }

      // login to dashboard
      let logindata = {
        endpoint: '?isuservalid',
        body: loginForm
      }
      
      if( !user ) adminLoginCookies();
      if( loginForm ) adminLoginHandler (logindata);


    },[loginForm])



  /*
    ------------------------------
    Admin Login Handler
    ------------------------------
  */

  const adminLoginFinish = ( values ) => {
    setLoginForm(values);
    setLoginLoadings(true);
  }
  
  /**
   * 
   * 
   * Login with cookies
   * 
   * */ 
  const adminLoginCookies = () => {
    const username = getCookie('username');
    const sid = getCookie('sid');

    if( username && sid ) {
      setLoginLoadings(true)
       let data = {
          endpoint: '?isuservalid',
          body: {
             username: JSON.parse(username),
             sid: JSON.parse(sid),
          }
       }

       api.post(data, res => {
          if( res.code === 200 && res.body !== null) {
             let data = JSON.parse(res.body.data);
             res.body.data = data;
             setUser(res.body);
             setLoginLoadings(false)
          } else {
             setUser(false);
             setLoginLoadings(false)
          }
       })
    }

  }

  /**
   * 
   * 
   * @function adminLoginHandler
   * Login with username and password newly typed  
   * 
   * 
   */ 
  const adminLoginHandler = ( data ) => {

    api.post(data, res => {
          if( res.code === 200 && res.body !== null) {
              
            let data = JSON.parse( res.body.data );

              res.body.data = data;
              setUser( res.body );
              setCookie( 'username', JSON.stringify(res.body.username), 365 );
              setCookie( 'sid', JSON.stringify(res.body.sid), 365 ); 
              history.push('/admin');

            } else {
                notification.open({
                  message: <h3> Invalid Phone or Password </h3>,
                  description: <p>Make sure you used your phone number with country code</p>
                });
                playWarningSound();
            }

          setLoginLoadings(false)
      })
  }


/**
 * 
 * 
 * Router DOM
 * 
 * 
 */ 
  const RouteComponent = () => {
    const pathname = location.pathname;
    const tree = new Set([   
                '/admin',
                '/admin-services',
                '/admin-variation-price',
                '/see-photo',
                '/photo-capture',
                '/admin-reschedule',
                '/admin-users',
                '/admin-barbers',
                '/yearly-dayoff',
                '/individual-dayoff',
                '/client-info',
                '/promotion',
                '/client-database',
                '/break-time',
                '/dayoff'
              ])

    const rounterComponents = () => {
      return (
        <div className="admin-panel">
          <DashboardHeader/>
          <div className="dashboard-container">

            { pathname === '/admin' && <Dashboard/> }
            { pathname === '/admin-services' &&  <AdminServices/> }
            { pathname === '/see-photo' &&  <SeePhoto /> }
            { pathname === '/photo-capture' && <PhotoCapture /> }
            { pathname === '/admin-reschedule' && <ReSchedule /> }
            
            { 
              /**
               * 
               * 
               * Show the admin dashboard screen if the type = admin
               * 
               * */ 
              user.type === 'admin' && 
                <Fragment>
                    { pathname === '/admin-users' && <Users/> }
                    { pathname === '/admin-variation-price' && <VariationPrice/> }
                    { pathname === '/admin-barbers' && <AdminBarber/> }
                    { pathname === '/yearly-dayoff' && <YearlyDayOff/> }
                    { pathname === '/individual-dayoff' && <Individual_dayoff /> }
                    { pathname === '/client-info' && <ClientInfo /> }
                    { pathname === '/promotion' && <Promotion /> }
                    { pathname === '/client-database' && <ClientDatabase /> }
                    { pathname === '/break-time' && <BreakTime /> }
                    { pathname === '/dayoff' && <DayoffPage /> }
                </Fragment> 
            } 

          </div>
        </div>
      )
    }

    /**
     * 
     * show 404 page or login page or admin pages 
     * 
     */ 
    return tree.has( pathname ) ? !user ? <Login/> : rounterComponents()  : null
    
  }


  /**
   * 
   * 
   * Store the context value 
   * 
   */ 

  const contextValue = { 
    serviceOne, setServiceOne, serviceTwo, adminLoginFinish, setServiceTwo, loginLoadings, user, 
    setUser, location, adminUsers, setAdminUsers, setLoginLoadings, clientInfoEmail, timeslot, setTimeSlot,
    setClientInfoEmail, clientInfoNumber, setClientInfoNumber, serviceData, setServiceData, barberData, serBarberData,
    dayoff, setDayoff, breakTime, setBreakTime, allBarber, dateFormat, priceVariation, setPriceVariation
  }

  /**
   * 
   * 
   * return app js jsx markup and manage routes
   * 
   */
  return (
    <Switch>
        <HeaderContext.Provider 
          value={contextValue}>
          
          <Route exact path="/" component={Home} />
          <Route path="/barbers" component={Barbers} />
          <Route path="/checkout" component={Checkout} />
          <Route path="/reschedule" component={ReSchedule}/> 
          <Route path="/reviews" component={Reviews}/>
          <Route path="/thankyou" component={ThankYou}/>
          
          {      
          /**
           * 
           * 
           * manage admin screens and routes
           * 
           */
            RouteComponent() 
          }

        </HeaderContext.Provider>
      </Switch>
  )
}


export default App;
