import { useState, useEffect, useRef } from 'react';
import CameraPhoto, { FACING_MODES } from 'jslib-html5-camera-photo';
import {useLocation} from 'react-router-dom';
import { Button, Modal, notification, Input  } from 'antd';
import Api_Request from './../api/api-request';
import { resizeImage } from './helper';

const api = new Api_Request();

/*----------------------------
Photo capture main component
-----------------------------*/ 
const PhotoCapture = () => {

    const {state} = useLocation();
    const [photos, setPhotos ] = useState([]);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [c, setC] = useState(0);
    const [load, setLoad] = useState(false);
    const [des, setDes ] = useState('');
    
    const [cameraPhotos, setCameraPhotos] = useState(null);
    const videoRef = useRef(null)
    
    useEffect(()=>{
        const init = new CameraPhoto(videoRef.current);
        setCameraPhotos(init)
    },[])


    if( !state ) {
        window.location.href = 'https://bigapplebarbershop.com/';
        return false;
    }


    /*-----------------------------
    Prepare data to upload through api
    -------------------------------*/ 
    const data = {
        phone: state.phone_number,
        name: state.first_name + ' ' + state.last_name,
        date: state.date,
        email: state.email_address,
        barber: state.barber_name,
        slot: state.slot,
        des: des.replaceAll("'", ''),
    }


    /*-----------------------------
    Take Maximum 3 photos
    -------------------------------*/ 
    const startCamera = (idealFacingMode, idealResolution) => {
        cameraPhotos.startCamera(idealFacingMode, idealResolution)
          .then(() => {
            console.log('camera is started !');
          })
          .catch((error) => {
            console.error('Camera not started!', error);
          });
    }
    
    
    const  takePhoto = () => {
        const config = {
          sizeFactor: 1
        };
        
        if( photos.length === 3 || photos.length > 2) {
            notification.open({
                message: <h3> 3 photos maximum </h3>,
            });
        } else {
            let dataUri = cameraPhotos.getDataUri(config);

            resizeImage(dataUri, 150).then( file => {
                setPhotos([...photos, file]);
            } )


            
        }

        
      }
    
    const stopCamera = () => {
        cameraPhotos.stopCamera()
          .then(() => {
            console.log('Camera stoped!');
          })
          .catch((error) => {
            console.log('No camera to stop!:', error);
          });
    }


    // show photo in bigger size
    const showModal = (i) => {
        setC(i);
        setIsModalVisible(true);
    };
    
    // delete photo
    const deletePhoto = (i) => {
        if( i === 0 ) {
            photos.shift();
            setPhotos(photos)
        }
        let dd = [...photos].splice(i);
        setPhotos(dd);
    }

    /*-----------------------------
    Upload Photos through API
    -------------------------------*/ 
    const upload = async () => {

        const promise = new Promise((resolve, reject) => {
            const files = [];

            photos.map( (item, index) => {
                const random = Math.floor(Math.random() * 100000);
                const fileName = `${random}__${state.token}__${index}.jpg`;
                const args = {
                    file: {
                       name: fileName,
                       base: item,
                    },
                    action: 'upload'
                 }

                 api.upload(args, callback => {
                    if( callback.code === 409 || callback.code === 202 ) {
                        files.push( `${api.apiRoot}upload/${fileName}` ) ;
                    } 
                })


            } )

            const intv = setInterval(()=>{
                if( files.length > 2) {
                    clearInterval(intv);
                    resolve(files)
                }
            },300)

          });

       
       return promise;

    }


    /*-----------------------------
      Upload Button Handler
    -------------------------------*/ 
    const uploadPhoto = () => {
        setLoad(true);
        upload().then(res => {           
            data.photos = res;
            
            let args = {
                endpoint: '?clientprofile',
                body: data
             }
          
             api.post(args, r => {
                setLoad(false);
                if( r.code === 202 ) {
                    notification.open({
                        message: <h3> Successful </h3>,
                    });
                    
                    window.history.back();
                } 
             })

        })
    }


      
    /*-----------------------------
     Component Markup
    -------------------------------*/ 
    return (
        <div className="image-capture">
            <h2>Take photos</h2>
            

            <div className="ashraf-camera">

                <video ref={videoRef} autoPlay={true} />

                <div className="camera-button">
                    <button onClick={ () => {
                    let facingMode = FACING_MODES.ENVIRONMENT;
                    let idealResolution = { width: 640, height: 480 };
                    startCamera(facingMode, idealResolution);
                    }}> Take a photo </button>

                    <button onClick={ () => {
                    let facingMode = FACING_MODES.USER;
                    startCamera(facingMode, {});
                    }}> Front Camera </button>

    
                    <button onClick={ () => {
                    takePhoto();
                    }}> Save </button>

                    <button onClick={ () => {
                    stopCamera();
                    }}> Off </button>
                </div>



            </div>



            <div className="photo-previews"> 
                {photos.map( (ph, index) => <div key={index}> 
                    <Button onClick={() => deletePhoto(index)}>Delete</Button>
                    <img onClick={()=>showModal(index)} src={ph}/> 
                </div> )}
            </div>

            <div className="client-details">
                <p> <strong>Name:</strong> {state.first_name + ' ' + state.last_name}</p>
                <p> <strong>Phone:</strong> {state.phone_number}</p>
                <p> <strong>Email:</strong> {state.email_address}</p>
                <p> <strong>Date:</strong> {state.date}</p>
                <p> <strong>Barber:</strong> {state.barber_name}</p>

                <Input.TextArea value={des} onChange={(val) => setDes(val.target.value)} style={{maxWidth: '600px', minHeight: '200px', marginTop: '20px'}}> </Input.TextArea>
                <br/>
                { photos.length === 3 && des.length ? <Button onClick={uploadPhoto} loading={load}  type="primary">Save Changes</Button> : null}
                

            </div>


            <Modal 
                visible={isModalVisible} 
                className="ashraf-see-photos"
                onOk={()=> setIsModalVisible(false)} 
                onCancel={()=> setIsModalVisible(false)}>
                <img src={photos[c]}/>
            </Modal>

        </div>
    )
}

export {PhotoCapture}

