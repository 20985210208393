import { useContext} from 'react';
import { Form, Input, Button } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import {HeaderContext} from './../api/api-context';

const Login = () => {

   const { loginLoadings, adminLoginFinish } = useContext( HeaderContext );
   
   return (
      <div className="admin-login-page">
            <Form
               name="normal_login"
               className="admin-login-form"
               initialValues={{ remember: false }}
               onFinish={adminLoginFinish}
            >
            
               <Form.Item name="phone" rules={[{ required: true, message: 'Enter phone number with country code' }]}>
                  <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Phone Number with country code" />
               </Form.Item>

               <Form.Item  name="password" rules={[{ required: true, message: 'Please input your Password!' }]}>
                  <Input prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="Password" />
               </Form.Item>
      
               <Form.Item>
                  <Button loading={loginLoadings}  type="primary" htmlType="submit" className="login-form-button"> Login Now  </Button>
               </Form.Item>
            </Form>
      </div>
   );

}

export {Login};