import { Drawer } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'

export default function ProfileDrawer( props ) {

    const details = props.details || {};

    return (
        <>
        <Drawer
            title={ details.position + ' Details' }
            placement="bottom"
            onClose={props.onClose}
            visible={props.open}
            className="profile-info"
        >
            <picture>
                <img className='profile-image' width={150} height="150" loading="lazy" src={details.photo} alt='barber-image'/>
            </picture>
            
            <h2><span>Name: </span>{details.name}</h2>
            <label>Specialities</label>
            <div className="barber-spalcities">
                {(details.skills || []).map( (skill, k) => 
                <p key={ k } className={skill.sign + " barber-spalcities--item"}> 
                    { skill.sign === 'good' ? <CheckOutlined /> : <CloseOutlined /> }
                    <span> { skill.value } </span> </p>   
                )}
            </div>
        </Drawer>
        </>
    )
};
