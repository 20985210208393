import { useState, useContext } from 'react';
import { Button, Select, notification, Card } from 'antd';
import { slotToTime, indexToWeekDay} from './../../utils'; 
import { HeaderContext } from '../../api/api-context';

import Api_Request from './../../api/api-request';
import Context from './context';

const api = new Api_Request();

/**
 * 
 *  Component for viewing break time
 * 
 * 
 */ 
export default function View() {

    const [ barber, setBarber ] = useState('all');

    const { breakTime, setBreakTime, allBarber } = useContext(HeaderContext)
    const contextData = useContext(Context)

    return (
        <>
            <div className="filter-panel">
               <h3 className="filter-title"> Filter the break time </h3>
               <Select size="large" style={{width: '250px'}} className="filter-barber" defaultValue="all" onSelect={setBarber}>
                    {
                       Object.entries( allBarber ).map(([username, item]) => <Select.Option key={username} value={username}>{item.name}</Select.Option>)
                    }
               </Select>
            </div>

            <div style={{padding: "40px 0"}} className="admin-dayoff-card">
                <div className="dayoff-card">
                    {  
                    /**
                     * 
                     * 
                     * execute this block when filter = all 
                     * 
                     */ 
                    barber === 'all' && 
                    Object.entries( allBarber ).map(([username, item]) => {
                        return (
                            <Card key={username} title={ <h3>{ item.name }</h3> } bordered={true}>
                                <View.Content contextData={contextData} username={username} breakTime={breakTime} setBreakTime={setBreakTime}/>
                            </Card>
                        )
                    })
                    }

                    {  
                    /**
                     * 
                     * 
                     * execute this block when filter = any specific barber
                     * 
                     */ 
                    barber !== 'all' &&
                    <Card title={<h3>{ allBarber[barber].name}</h3> } bordered={true}>
                        <View.Content contextData={contextData} username={barber} breakTime={breakTime} setBreakTime={setBreakTime}/>
                    </Card>
                    }
                    
                </div>
            </div>
        </>
       
    )
}


View.Content = ({ contextData, username, breakTime, setBreakTime}) => {
    
    if( !username || !breakTime || !breakTime[username] ) {
        return null;
    }

    const data = breakTime[username];
    const { setVisible, setBreakTimeState, setAction, setDisableSlot } = contextData;

    return (
        <div className="inner-card-container">
            {  
                /**
                 * 
                 * 
                 * Loop Through the mode like, everday, custom, weekly  
                 * 
                 */ 
                Object.keys(data).map( item => {
                return(
                        <Card key={ username + item } title={item} 
                            actions={[
                            <Button 
                                size="large"
                                onClick={()=>{
                                    setAction('edit');
                                    setDisableSlot(false);
                                    setBreakTimeState( prevState => {
                                        prevState.type = username;
                                        prevState.mode = item;
                                        prevState.time = data[item];
                                        return {...prevState};
                                    })
                                    setVisible(true);
                                }}
                            >
                            
                            Edit</Button>,
                            <Button 
                                size="large"
                                onClick={()=>{

                                    let args = {
                                        endpoint: `/?break-time&type=${username}&mode=${item}`
                                    }
                                
                                    api.delete(args, res => {
                                
                                        if( res.code === 200 ) {
                                            notification.open({
                                                message: <h4> Delete Successful </h4>,
                                            });

                                            setBreakTime( prevState => {
                                                delete prevState[username][item];
                                                return {...prevState};
                                            })  
                                        
                                        } else {
                                            notification.open({
                                                message: <h4> Something went wrong! </h4>,
                                                description: <p> Could not delete. Please try again </p>
                                            });
                                        }
                                    })

                                }}
                            >Delete
                            </Button>,
                            ]}
                        >
                            {
                            /**
                            * 
                            * 
                            * loop through the mode item
                            * 
                            */ 
                            <ul>
                                {
                                    data[item].map( (item_type, key) => 
                                        <li key={key}>
                                        { item === 'weekly' && <label> { indexToWeekDay(item_type?.date) } </label> }
                                        { item === 'custom' && <label> { item_type?.date } </label>}
                                        <p>{ item_type?.note }</p>
                                        <div>
                                            { item_type?.slot.map( (item, key) => <span className="dayoff-card-date" key={key}>{ slotToTime(item) }</span> )}
                                        </div>
                                        </li> 
                                    )
                                }
                            </ul>
                            }
                    </Card>
                );
                } )
            }
        </div>
    )
}