import { Link } from 'react-router-dom';
import { Button, Input } from 'antd';
import { Fragment, useState } from 'react';
import ProfileDrawer from './profile';

import { ResponseAlert } from './../utils';
import { compareWithIndividualPrice, dayFromWeek, showVIPbox, getVIPprice } from './../helper';

export default function BarbersContainer(props) {
   let alldays = [1,2,3,4,5,6,7];
   const [ open, setOpen ] = useState( false );
   const [ details, setDetails ] = useState({});

   function weekEnds( weekend ) {
      return (
         <div className="weekends">
            { alldays.map( (day, key ) => dayFromWeek(day, weekend, key) ) }
         </div>
      )
   }

   const openBarberDetails = details => {
      setDetails(details)
      setOpen(true)
   }

   const applyVIPCode = (code, index) => {
      props.setData( prevState => {
         prevState[index].vip_code = code
         return [...prevState]
      } )
   }
 
   function innerComponent( item, key ) {
 
      const price = compareWithIndividualPrice(
         props.priceVariation,
         props.serviceOne,
         item
      );
 
      const clickHandler = () => {
         props.nextitem('barbers', item)
      }

      const showVIP = showVIPbox(
         props.priceVariation,
         props.serviceOne,
         item
      );

      const vip_price = getVIPprice(
         props.priceVariation,
         props.serviceOne,
         item.username,
         item.vip_code || ''
      )

      return (
         <div className="barber-item">
            <div className="barber-item__header"> 
               <img onClick={() => openBarberDetails(item)} className="image" width="70" height="70" loading="lazy" src={ item.photo } alt="barber"/>
               <span className="service">{props.serviceOne.title}</span>
               { vip_price ? (
                  <div className="vip-prices">
                     <span className='vip'>{vip_price}</span>
                  </div>
               ): (
                  <div className="price">{price}</div>
               ) }
            </div>
            <div className='barber-item__meta'>
               <span onClick={() => openBarberDetails(item)} className='name'>{ item.name } </span>
               <span className="barber-item__content--dayoff"> {weekEnds(item.weeklyOff)} </span>
            </div> 

            <div className="barber-item__body"> 
               <p className="barber-item__content--notice">{item.notice}</p>
            </div>
            
            <div className='barber-item__footer'>
               { showVIP  && (
                  <div className='vip-code'>
                     <label>VIP Code</label>
                     <Input value={item.vip_code || ''} onChange={ev => applyVIPCode(ev.target.value, key)} size='large' placeholder='Optional'/>
                  </div>
               )}
               <Link key ={ key } to="/checkout" onClick={clickHandler}>
                  <Button size='large' className='continue-btn'>Continue</Button>
               </Link>
            </div>
         </div> 
      );     
   }
 
   if( props.data.length ) {
      return (
         <Fragment>
            <div className="barbers-container">
               { props.data.map( (item, key) => <Fragment key={key}>{innerComponent(item, key)}</Fragment> ) } 
            </div> 
            <ProfileDrawer details={details} open={open} onClose={() => setOpen(false)}/>  
         </Fragment>
      )
   } else {
      return ResponseAlert({code:204, message: "No Content"}) // 204 = no content
   }
 
 }