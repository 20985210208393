export function compareWithIndividualPrice( priceVariation, serviceDetails, barberDetails ) {
    if( !priceVariation || !priceVariation.enable_individual_default_price || !serviceDetails.individual_price ) {
        return serviceDetails.price;
    }

    const barber = `${barberDetails.username}_default_price`;

    if( serviceDetails[barber] && typeof serviceDetails[barber] === 'string' && serviceDetails[barber].trim().length > 1 ) {
        return serviceDetails[barber]
    }

    return serviceDetails.price;
}

export function showVIPbox( priceVariation, serviceDetails, barberDetails ) {

    if( !priceVariation || !Reflect.has( priceVariation, 'enable_vip_price' ) ) {
        return false;
    }


    if(
       priceVariation && // must have price variation
       priceVariation.enable_vip_price && // must have vip price enable
       typeof priceVariation.vip_code === 'string' && // vip code must be a string
       priceVariation.vip_code.trim().length > 0 // vip code can not empty 
    ) {

       if( serviceDetails.individual_vip_price ) {
          const barber = `${barberDetails.username}_vip_price`;
          if( serviceDetails[barber] && typeof serviceDetails[barber] === 'string' && serviceDetails[barber].trim().length > 0 ) {
             return true;
          }
          return false;
       } 

       if( serviceDetails.vip_price && typeof serviceDetails.vip_price === 'string' && serviceDetails.vip_price.trim().length > 0 ) {
          return true;
       }

       return false;

    } 

    return false;
}

export function getVIPprice( priceVariation, serviceDetails, barberUsername, vipCode ) {

    if( !priceVariation || !Reflect.has( priceVariation, 'enable_vip_price' ) ) {
        return false;
    }
    
    if( !priceVariation.enable_vip_price || !vipCode || (typeof priceVariation.vip_code === 'string' && priceVariation.vip_code.length === 0) ) {
        return false
    }

    if( priceVariation.vip_code === vipCode ) {
        if( serviceDetails.individual_vip_price ) {
            const barber = `${barberUsername}_vip_price`;
            return serviceDetails[barber];
        }

        return serviceDetails.vip_price
    }

    return false
}

export function getReschedulePrice( userDetails, serviceDetails, priceVariation ) {
    
    const vip_barber = userDetails.barber_username + '_vip_price';
    const ind_barber = userDetails.barber_username + '_default_price';

    if (
        userDetails.vip_user && // vip object
        userDetails.vip_user === 'yes' && // is vip user
        priceVariation &&
        priceVariation.enable_vip_price 
    ) {
        if( serviceDetails.individual_vip_price && serviceDetails[vip_barber] ) {
            return serviceDetails[vip_barber];
        }

        if( serviceDetails.vip_price ) {
            return serviceDetails.vip_price
        }

        if( serviceDetails.individual_price && serviceDetails[ind_barber] ) {
            return serviceDetails[ind_barber];
        }

        return serviceDetails.price;
    }

    if( priceVariation.enable_individual_default_price && serviceDetails.individual_price && serviceDetails[ind_barber] ) {
        return serviceDetails[ind_barber];
    }

    return serviceDetails.price;
}

export function dayFromWeek (no, weekend, key) {
    let day = 'Sat';
    switch (no) {
       case 1: day = 'Sun'; break;
       case 2: day = 'Mon'; break;
       case 3: day = 'Tue'; break;
       case 4: day = 'Wed'; break;
       case 5: day = 'Thu'; break;
       case 6: day = 'Fri'; break;
       case 7: day = 'Sat'; break;
       default: day = 'Sun'; 
    }
    let is_active = weekend.indexOf(no) !== -1 ? 'disable' : 'enable';
    return <span key={key} className={is_active}>{day}</span>;
 }