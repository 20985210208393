import {useState, useEffect, useContext, useRef} from 'react';
import { Button, notification } from 'antd';
import { Loading } from '../utils';
import Api_Request from './../api/api-request';
import { HeaderContext } from '../api/api-context';
import DatePicker from "react-multi-date-picker";

const api = new Api_Request();

/*---------------------------
Components
-----------------------------*/ 
const YearlyDayOff = () => {

   const {officialDayOff, setOfficialDayOff} = useContext(HeaderContext);
   const [pageLoaded, setPageLoaded ] = useState(false);
   const [loading, setLoading] = useState(false);
   const [date, setDate] = useState([]);
   const dateRef = useRef(null);
   

   useEffect(()=>{
      
      if( !officialDayOff.length ) {
         api.get('/?official_dayoff', res =>{
            if( res.code === 200 ) {
               setOfficialDayOff(res.body.content);
               setPageLoaded(true)
            }
          })
      } else {
         setPageLoaded(true)
      }


   },[])

   function uniqueValues (a) {
      return [...new Set(a)];
   };

   /*------------------------------
   update api
   --------------------------------*/ 
   const updatDatabse = (arr, type) => {
      setLoading(true)
      const data = {
         endpoint: '?official_dayoff',
         body: type === 'delete' ? arr : uniqueValues([...officialDayOff, ...arr])
      }

      api.post(data, res =>{
         if( res.code === 202 ) {
            setOfficialDayOff(res.body);
            setLoading(false)
            notification.open({
               message: <h3> Successful </h3>,
            });
         }
      })
   }

   /*------------------------------
   Clar All
   --------------------------------*/ 
   const clearall = () => {
      setLoading(true)
      const data = {
         endpoint: '?official_dayoff',
         body: [],
      }

      api.post(data, res =>{
         if( res.code === 202 ) {
            setOfficialDayOff(res.body);
            setLoading(false)

            notification.open({
               message: <h3> Cleared All </h3>,
            });
         }
      })
   }




   /*------------------------------
   Delete from dayoff list
   --------------------------------*/
   const deleteDays = day => {
      const index = officialDayOff.indexOf(day);
      const aa = [...officialDayOff];
      aa.splice(index, 1);
      updatDatabse(aa, 'delete')
   }

   const updateYearlyDayoff = () =>{
      const value = dateRef.current.querySelector('.rmdp-input').value.trim()

      if( value.length ) {

         const v = value.split(', ');
         setDate([])
         updatDatabse(v);

      } else {
         notification.open({
            message: <h3> Pick Date </h3>,
         });
      }


   }

   return (
      <> 
      {!pageLoaded ? <Loading/> :
      <div className="yearly-dayoff-container">
         <h3>Add Official Dayoff</h3>
         <div className="display-dayoff">
            <ul>
               {officialDayOff.map((item, key) => <li key={key} onClick={()=>deleteDays(item)}> {item} </li>)}
            </ul>
            
         </div>

        <DatePicker placeholder="Select a date" ref={dateRef} format="M-D-YYYY" multiple value={date} onChange={setDate} />

        <br/>
        <br/>

        <Button type="primary" loading={loading}  className="btn-submit" onClick={updateYearlyDayoff} size="large"> Save </Button> 
        <Button type="primary" loading={loading} onClick={clearall} danger size="large">Delete All</Button>

      </div>
      }
      </>
   )
}

export {YearlyDayOff}