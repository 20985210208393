import { useEffect, useState, Fragment, useContext } from 'react';
import { Drawer, Button, Select, Card, notification } from 'antd';
import { Calendar } from "react-multi-date-picker"
import { makeDate } from '../utils';
import { HeaderContext } from '../api/api-context';
import Api_Request from './../api/api-request';
const api = new Api_Request();

/*============================
React Component start
=============================*/ 

export default function DayoffPage() {
   /**
    * 
    * React States 
    * 
    */ 
   const [ barber, setBarber ] = useState('all');                /* Set Individual barber data */
   const [ allBarber, setAllBarber ] = useState({
      all: {
         name: 'All - Official',
         weeklyOff: [],
      }
   });  
   const [ visible, setVisible ] = useState(false);              /** @const visible used to open or close the dware */ 
   const [ loading, setLoading ] = useState(false);              /* Manage circle progress loading status */
   const [ action, setAction ] = useState('none'); 
   const [ dayoffState, setDayoffState ] = useState({
      type: 'all',
      date: []
   });

   /**
    * 
    * Context API 
    * 
    */ 
   const {dayoff, barberData, setDayoff} = useContext(HeaderContext);

/**
 * 
 * 
 * Fetch all barber details from the server
 * 
 */ 
useEffect(() => {
   barberData.map( item => {
      setAllBarber( prevState => {
         return {
            ...prevState,
            [item.username] : {
               name: item.name,
               weeklyOff: item.weeklyOff
            }
         }
      } )
   } );

}, [barberData])



/**
 * 
 * 
 * Add or update dayoff
 * 
 */

const onSubmit = () => {
   
   const date = () => {
      if( action === 'add' && dayoff[dayoffState.type] ) {
         return [...dayoffState.date, ...dayoff[dayoffState.type]]
      } 

      if( action === 'add' && !dayoff[dayoffState.type] ) {
         return dayoffState.date
      } 

      if( action === 'edit' ) {
         return dayoffState.date
      }

   }

   const body = {
      type: dayoffState.type,
      date: date()
   }

   setLoading(true)
   const data = {
      endpoint: '?dayoff',body
   }

   api.post(data, res =>{
      if( res.code === 202 ) {
         setDayoff( prevState => {
            return {
               ...prevState,
               [body.type] : body.date
            }
         } )
         setLoading(false)
         setVisible(false);
         notification.open({
            message: <h3> Successful </h3>,
         });
      }
   })
}

/**
 * 
 * 
 * edit dayoff
 * 
 */

 const editDayoff = type => {
    setDayoffState({type, date: dayoff[type] })
    setAction('edit');
    setVisible(true);
 }

/**
 * 
 * 
 * Delete dayoff
 * 
 */
const deleteDayoff = type => {
   setLoading(true)
   let args = {
      endpoint: `/?dayoff=${type}`
   }

   api.delete(args, res => {

      if( res.code === 200 ) {
         notification.open({
            message: <h4> Delete Successful </h4>,
         });
         delete dayoff[type]
      } else {
         notification.open({
            message: <h4> Something went wrong! </h4>,
            description: <p> Could not delete the dayoff. Please try again </p>
          });
      }
      setLoading(false);
   })
}

/**
 * 
 * 
 * Dayoff content show 
 * 
 */ 
const showDayoffContent = ( type, date ) => {
   return date.map( (item, key) => <span className="dayoff-card-date" key={key}>{ item }</span> );
}

/**
 * 
 * 
 * Break time JSX Markup 
 * 
 * 
 */ 
return (
   <div className="admin-break-time-page">
      <Fragment>
         <h2> Dayoff Settings </h2>
         <hr/> <br/>
         <div className="filter-panel">
            <h3 className="filter-title"> Filter the dayoff </h3>
            <Select size="large" style={{width: '250px'}} className="filter-barber" defaultValue="all" onSelect={setBarber}>
               <Select.Option key="all" value="all"> All </Select.Option>
                  {barberData.map((item, key) => <Select.Option key={key} value={item?.username}>{item?.name}</Select.Option>)}
            </Select>
         </div>

         <div style={{padding: "40px 0"}} className="admin-dayoff-card">
            <div className="dayoff-card">
               {
                  barber === 'all' && 
                  Object.keys(allBarber).map( username =>  {
                     if( dayoff && dayoff[username] ) {
                        return (
                           <Card 
                              key={username} 
                              actions={[
                                 <Button disabled={loading} size="large" onClick={ () => editDayoff( username ) }>Edit</Button>,
                                 <Button disabled={loading} size="large" onClick={ () => deleteDayoff( username ) }>Delete</Button>,
                              ]}
                              title={ <h3>{allBarber[username].name}</h3> } bordered={true}
                           >
                              { showDayoffContent( username, dayoff[username] ) }
                           </Card>
                        )
                     } 
                  })
               }

               {
                  barber !== 'all' && dayoff[barber] &&
                  <Card 
                     actions={[
                        <Button disabled={loading} size="large" onClick={ () => editDayoff( barber ) }>Edit</Button>,
                        <Button disabled={loading} size="large" onClick={ () => deleteDayoff( barber ) }>Delete</Button>,
                     ]}
                     title={<h3>{allBarber[barber].name}</h3> } bordered={true}
                  >
                     { showDayoffContent( barber, dayoff[barber] ) }
                  </Card>
               }
               
            </div>
         </div>

         <div className="dayoff-footer">
            <Button 
               disabled={loading} 
               type="primary" 
               size="large" 
               loading={loading} 
               onClick={ () => {
                  setVisible(true);
                  setAction('add');
               } }
            >Add Dayoff
            </Button>
         </div>

         <Drawer
            title="Create Day off"
            className="admin-break-time-drawer"
            placement="right"
            closable={true}
            forceRender
            onClose={() => setVisible(false)}
            visible={visible}
         >

            <div className="break-time-layout">
               <div className="break-time-layout__item">
                     <label className="label"> Select Barber </label>
                     <div className="content">
                        <Select 
                           disabled={ action !== 'add' } 
                           size="large" 
                           style={{width: '100%'}} 
                           className="filter-barber" 
                           value={ dayoffState.type } 
                           onSelect={ type => {
                              setDayoffState({type, date: []})
                           }}
                        >
                              <Select.Option key="all" value="all"> All </Select.Option>
                              {barberData.map((item, key) => <Select.Option key={key} value={item?.username}>{item?.name}</Select.Option>)}
                        </Select>
                     </div>

               </div>

               <div className="break-time-layout__item">
                     <label className="label"> Select One/Multiple Dates </label>
                     <div className="content">
                        <Calendar
                           shadow={false}
                           format="M-D-YYYY"
                           minDate={ action === 'add' ? new Date() : makeDate('1-1-2021', true) }
                           value={ (()=> dayoffState.date.map( item => makeDate(item, true) ) )() }
                           onChange={ val => {
                              setDayoffState( prevState => {
                                 return {
                                    ...prevState,
                                    date: val.map( item => item.format() )
                                 }
                              } )
                           } }
                           mapDays={({ date, today, selectedDate, currentMonth, isSameDate }) => {
                              let props = {}
                              let disableStyle = {
                                 borderRadius: "3px",
                                 background: "#fae8e8",
                                 color: '#ff0000',
                              };

                              props.style = {
                                 borderRadius: "3px",
                              }
                              
                              if( action === 'add' && dayoff[dayoffState.type] && dayoff[dayoffState.type]?.includes(date.format()) ) {
                                 props.style = disableStyle;
                                 props.hidden = true;
                              }

                              if( dayoffState.type !== 'all' && dayoff.all && dayoff.all?.includes(date.format()) ) {
                                 props.style = disableStyle;
                                 props.hidden = true;
                              }

                              if( allBarber[dayoffState.type]?.weeklyOff.includes( date.weekDay.index + 1 ) ) {
                                 props.style = disableStyle;
                                 props.hidden = true;
                              }
                              
                              return props

                              }}
                        />
                     </div>
               </div>

               {
                  dayoffState.date.length > 0 && <Button className="submit-btn" size="large" loading={loading} onClick={onSubmit} type="primary" block>Submit Now</Button>
               }
               
               
            </div>
               
         </Drawer>
      </Fragment>
   </div>
)

}
