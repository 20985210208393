import { useEffect, useState, useRef } from 'react';
import { Drawer, Button, Form, Select, Checkbox, notification, Switch, Row, Col } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { slotToTime, slotset } from './../utils'; 
import Api_Request from './../api/api-request';
import DatePicker from "react-multi-date-picker";

const api = new Api_Request();

/*============================
React Component start
=============================*/ 

const Individual_dayoff = () => {

   const [indayoff, setInDayoff ] = useState({});
   const [barber, setBarber ] = useState('');
   const [allBarbers, setAllBarbers ] = useState([]);
   const [visible, setVisible] = useState(false);  /** @const visible used to open or close the dware */ 
   const formRef = useRef();
   const [indeterminate, setIndeterminate] = useState(false); /* The indeterminate property can help you to achieve a 'check all' effect. */
   const [loading, setLoading] = useState(false);
   const [date, setDate] = useState([]);
   const dateRef = useRef(null);
   const dayoffarr = Object.keys(indayoff);


/**
@method postIntoServer(d,b,s)
==================================
@param d = date in array format
@param b = barber username
@param s = timeslots in array format

*/ 
const postIntoServer = (d, b, s ) => {
   const data = {
      endpoint: `?postinddayoff&barber=${b}`,
      body: {
         date: d,
         slots: s
      }
   }

   api.post(data, res =>{
      if( res.code === 202 ) {
         notification.open({
            message: <h3>Successful</h3>,
         });
         setVisible(false);
         setLoading(false)
         fetchIndivudaldayoff(b)
      }
   })
}


/**
@method fetchIndivudaldayoff(b)
==================================
@param b = barber username
*/ 
const fetchIndivudaldayoff = b => {
   api.get(`/?getinddayoff&barber=${b}`, res =>{
      if( res.code === 200 ) setInDayoff(res.body);
   })
}


// fetch data from server
useEffect(() => {

   // fetch stylst username from server
   api.get('/?barbers', res => {
      if( res.code === 200 && res.body.content !== null) {
         const bar = res.body.content.map(item => {
            return {
               u: item.username,
               n: item.name,
            }
         })

         setAllBarbers(bar);
         setBarber(barber || bar[0].u)

      }
   })

   fetchIndivudaldayoff(barber)
   

}, [barber])



/*-----------------------
Open Dware from right side
------------------------*/ 
const openDware = () => {
   formRef.current?.resetFields(); // rest form data
   setIndeterminate(false)
   setVisible(true)
}


/*-----------------------
on Form field change
------------------------*/ 
const onBarberChange = (val) => {
   setBarber(val);
   fetchIndivudaldayoff(val)
}

/*--------------------------
 Delte Dayoff from the list
---------------------------*/ 
const deleteDayOff = (date) => {
   setLoading(true);
   const copyDayoff = {...indayoff};
   const args = {
     endpoint: `?deleteindividualdayoff&b=${barber}&d=${date}`,
     body: ''
   }

   api.delete(args, res => {
    if(res.code === 200) {
      notification.open({
         message: <h3>Delete Successful</h3>,
      });
      setLoading(false)
      delete copyDayoff[date];
      setInDayoff(copyDayoff)

    }
  })
}

/*--------------------------
 Delete timeslot from date
---------------------------*/ 
const deleteTimeslot = (date, slot) => {

   const copyDayoff = {...indayoff}
   const copy = copyDayoff[date];
   const index = copy.indexOf(slot);
         copy.splice(index, 1);
         copyDayoff[date] = copy;

   postIntoServer([date], barber, copy)
   setInDayoff(copyDayoff)
   const is_empty = indayoff[date].length;

   // when all slots are deleted then delete the date too
   if( is_empty === 0 ) {
      deleteDayOff(date)
   }

}

/*-----------------------
on Form submit
------------------------*/ 
const onFormSubmit = (values) => {

   setLoading(true)
   const value = dateRef.current.querySelector('.rmdp-input').value.trim()

   if( value.length ) {

      const v = value.split(', ');
      setDate([])
      postIntoServer(v, barber, values.slots);
      fetchIndivudaldayoff(barber)

   } else {
      notification.open({
         message: <h3> Pick Date </h3>,
      });
      setLoading(false)
      return false;
   }


}

/*-----------------------
Component Markup
------------------------*/ 




const renderDayOffBlock = (item, key) => {
   return(
      <div className="admin-dayoff-item" key={key}>
         <h2 className="date-title"> <span>{item}</span> <Button onClick={() => deleteDayOff(item)} type="primary" danger>Delete</Button></h2>
         <div className="admin-dayoff-times">
            {indayoff[item].map((slot, k) => <span key={k} onClick={()=> deleteTimeslot(item, slot)}> {slotToTime(slot)} </span>)}
         </div>
      </div>
   )
}


const updateGroupCheckbox = () => {
   if( !indeterminate ) {
      formRef.current.setFieldsValue({slots: Array.from(slotset)})
   } else {
      formRef.current.setFieldsValue({slots: []})
   }
   setIndeterminate( !indeterminate );
}

const onGroupCheckboxChange = (val) => {
    val.length === 19 ? setIndeterminate( true ) : setIndeterminate( false )
}

return (
   <div className="admin-individual-dayoff">
      {barber ? <> 
      <div className="filter-panel">
         <h3 className="filter-title"> Filter the dayoff </h3>
         <Select className="filter-barber" defaultValue={barber} onSelect={onBarberChange}>
            {allBarbers.map((item, key) => <Select.Option key={key} value={item.u}>{item.n}</Select.Option>)}
         </Select>
      </div>

      <div className="admin-dayoff-container">
         {dayoffarr.map((item, key) => renderDayOffBlock(item, key))}
      </div>

      <div className="dayoff-footer">
         <Button type="primary" size="large" loading={loading} onClick={openDware}>Add Dayoff</Button>
      </div>

      <Drawer
         title="Add New Dayoff"
         className="admin-individual-dayoff-drawer"
         placement="right"
         closable={true}
         onClose={() => setVisible(false)}
         visible={visible}
      >

         <Form
            name="service-form"
            className="service-form"
            onFinish={onFormSubmit}
            ref={formRef}
         >
         
         <label className="label">Select One / Multiple date</label>
         <Form.Item  name="date"  rules={[{ required: true, message: 'Select Date' }]}>
            <DatePicker placeholder="Select a date" ref={dateRef} format="M-D-YYYY" multiple value={date} onChange={setDate} />
         </Form.Item>


         <label htmlFor="mark-all-timeslot" className="label mark-all-timeslot">Mark all time slot</label>

         <Switch
               id="mark-all-timeslot"
               size="large"
               checked={ indeterminate }
               onChange={ updateGroupCheckbox  }
               checkedChildren={<CheckOutlined />}
               unCheckedChildren={<CloseOutlined />}
         />
         <br/>
         <br/>
         <Form.Item name="slots"  rules={[{ required: true, message: 'Select Time Slots' }]}>
            <Checkbox.Group onChange={onGroupCheckboxChange}>
               <Checkbox value="slot_1" style={{lineHeight: '32px',}}> {slotToTime('slot_1')} </Checkbox>
               <Checkbox value="slot_2" style={{lineHeight: '32px',}}> {slotToTime('slot_2')} </Checkbox>
               <Checkbox value="slot_3" style={{lineHeight: '32px',}}> {slotToTime('slot_3')} </Checkbox>
               <Checkbox value="slot_4" style={{lineHeight: '32px',}}> {slotToTime('slot_4')} </Checkbox>
               <Checkbox value="slot_5" style={{lineHeight: '32px',}}> {slotToTime('slot_5')} </Checkbox>
               <Checkbox value="slot_6" style={{lineHeight: '32px',}}> {slotToTime('slot_6')} </Checkbox>
               <Checkbox value="slot_7" style={{lineHeight: '32px',}}> {slotToTime('slot_7')} </Checkbox>
               <Checkbox value="slot_8" style={{lineHeight: '32px',}}> {slotToTime('slot_8')} </Checkbox>
               <Checkbox value="slot_9" style={{lineHeight: '32px',}}> {slotToTime('slot_9')} </Checkbox>
               <Checkbox value="slot_10" style={{lineHeight: '32px',}}> {slotToTime('slot_10')} </Checkbox>
               <Checkbox value="slot_11" style={{lineHeight: '32px',}}> {slotToTime('slot_11')} </Checkbox>
               <Checkbox value="slot_12" style={{lineHeight: '32px',}}> {slotToTime('slot_12')} </Checkbox>
               <Checkbox value="slot_13" style={{lineHeight: '32px',}}> {slotToTime('slot_13')} </Checkbox>
               <Checkbox value="slot_14" style={{lineHeight: '32px',}}> {slotToTime('slot_14')} </Checkbox>
               <Checkbox value="slot_15" style={{lineHeight: '32px',}}> {slotToTime('slot_15')} </Checkbox>
               <Checkbox value="slot_16" style={{lineHeight: '32px',}}> {slotToTime('slot_16')} </Checkbox>
               <Checkbox value="slot_17" style={{lineHeight: '32px',}}> {slotToTime('slot_17')} </Checkbox>
               <Checkbox value="slot_18" style={{lineHeight: '32px',}}> {slotToTime('slot_18')} </Checkbox>
               <Checkbox value="slot_19" style={{lineHeight: '32px',}}> {slotToTime('slot_19')} </Checkbox>

            </Checkbox.Group>
         </Form.Item>
         


         <Form.Item>
            <Button size="large" loading={loading}  type="primary" htmlType="submit" className="login-form-button"> Save </Button>
         </Form.Item>

         </Form>

      </Drawer>
      </> : null}
   </div>
)

}

export {Individual_dayoff};