import { Button } from 'antd';
/**
 * 
 * 
 * 
 * Add a new break time item
 * 
 * 
 */
export default function AddNewButton( props ) {
    return (
        <Button 
            block 
            size="large" 
            disabled={ props.disabled }
            onClick={ props.onClick }
            type="primary" 
            style={{margin: "10px 0"}}
        > {props.children} </Button>
    )
}