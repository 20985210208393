import { useState } from 'react';
import { useHistory } from "react-router-dom";
import { Input, notification, Button  } from 'antd';
import Api_Request from './../api/api-request';
import useSound from 'use-sound';
import warningSound from './../assets/sounds/warning.mp3';

const api = new Api_Request();
let d = new Date()
let currentDate = (d.getMonth() + 1) + '-' + d.getDate() + '-' + d.getFullYear();

const Promotion = () => {
   const history = useHistory();

   if( !history.location.state ) {
      window.location.href = window.location.origin + '/client-info'
   }

   const { keys, type } = history.location.state
   const [load, setLoad] = useState(false);
   const [playWarningSound] = useSound(warningSound);
   const [info, setInfo] = useState({
      subject: '',
      body: ''
   })


   const onInputChange = (val, field ) => {
      const aa = {...info};
      aa[field] = val;
      setInfo(aa)
   }

   const onSubmit = () => {

      if( info.body.length ) {
         setLoad(true)
         const args = {
            endpoint: '?corn-job&action=promotion',
            body: {
               subject : info.subject,
               body: info.body,
               keys: keys,
               type: type,
               date: currentDate,
            }
         }

         api.post( args, res => {

             if( res.code === 202 ) {
               notification.open({
                  message: <h3> Successful </h3>,
                  description: <p> Every 5 mins {type === 'email_tb' ? 'Email' : 'Text'} promotion will be sent </p>
               });
             } 
             else if (res.code === 409 ) {
               notification.open({
                  message: <h3> Already Running </h3>,
                  description: <p> Currently { res.body } {type === 'email_tb' ? 'Email' : 'Text'} are yet to send. Please wait </p>
               });
               playWarningSound()
             }

            setLoad(false)
         })

      } // end of api call
      else {
         notification.open({
            message: <h3> Write something </h3>,
            description: <p>Body can not be empty. Please write something for promotion</p>
         });
         playWarningSound()
      }

  

   }


   return (
      <div className="admin-promotion">
         <h2 className="admin-promotion--title"> Send {type === 'email_tb' ? 'Email' : 'Text'} Promotion</h2>
         
         {type === 'email_tb' ? <>
            <Input value={info.subject} onChange={(v) => onInputChange(v.target.value, 'subject')} placeholder="Subject"/> 
            <br/> <br/>
         </>: null }

         <Input.TextArea
            placeholder="Message/Body"
            value={info.body}
            onChange={(v) => onInputChange(v.target.value, 'body')}
            autoSize={{ minRows: 5, maxRows: 15 }}
         />
         <br/>
         <br/>
         <Button onClick={onSubmit} loading={load} type="primary"> Start Sending </Button>
      </div>
   )
}

export {Promotion}