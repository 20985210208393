import { useState, useEffect, useContext } from 'react';
import { Form, Input, Button, Select, DatePicker, notification, Modal, Checkbox } from 'antd';
import { NumberOutlined, LockOutlined, ExclamationCircleOutlined  } from '@ant-design/icons';
import { slotToTime, Header, PageTitle, currentTime, onlyAvailableTimeSlot } from './utils';
import { HeaderContext } from './api/api-context';

import Api_Request from './api/api-request';
import moment from 'moment';
import useSound from 'use-sound';
import warningSound from './assets/sounds/warning.mp3';
import happySound from './assets/sounds/happy_ending.mp3';
import thankYou from './assets/images/thank-you.jpg';
import { getReschedulePrice } from './helper';

const api = new Api_Request();

function getUrlVars() {
   var vars = {};
   var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi,    
   function(m,key,value) {
     vars[key] = value;
   });
   return vars;
 }


/**
 * 
 * 
 * Reschedule Page Component 
 * 
 * 
 */  
export default function ReSchedule() {

   const [token, setToken ] = useState('');
   const [origin, setOrigin] = useState('');

   const [login, setLogin ] = useState(false);
   const [loginLoadin, setLoginLoading] = useState(false);
   const [cancelLoading, setCancelLoading] = useState(false);
   const [updateLoading, setUpdateLoading] = useState(false);
   const [slotLoading, setSlotLoading] = useState(false);
   const [weeklyOff, setWeeklyOff] = useState({});
   const [userDetails, setUserDetails ] = useState({});
   const [ readPolicy, setReadPolicy ] = useState(false);

   const [playWarningSound] = useSound(warningSound);
   const [playHappySound] = useSound(happySound);
   const [ availableSlot, setAvailableSlot ] = useState({
      available: false,
      slot:[]
   });

   const { priceVariation, serviceData, barberData, dayoff, breakTime, timeslot, setTimeSlot } = useContext( HeaderContext );

   const dateFormat = 'M-D-YYYY';

   /**
    * 
    * set weekly off 
    * 
    */ 

   useEffect(()=>{
      barberData.map( item =>{
         setWeeklyOff(prevState => {
            prevState[item.username] = item.weeklyOff;
            return {...prevState}
         })
      } )
   },[barberData])


   /**
    * 
    * 
    * Get login information from url 
    * 
    */ 
   useEffect(()=>{
      
      setLogin(false);
      setLoginLoading(false);
      setUpdateLoading(false);
      setCancelLoading(false);

      const param = getUrlVars();
      setOrigin(param.origin);
      if( param.hasOwnProperty('p') && param.hasOwnProperty('t') ) {
         setToken(param.t);
         onFinish({
            token: param.t,
            phone: param.p
         })
      }

   }, [])

    
   const invalidNotification = () => {
      notification.open({
         message: <h3> Invalid Token or Phone number </h3>,
         description: <p> Make sure you did not write country code with phone number </p>
       });
      playWarningSound();
      setLoginLoading(false);
   }

   /**
    * 
    * 
    * on login button click
    * 
    */
   const onFinish = (values) => {
      setLoginLoading(true);

      // check phone number digit length (max:10)
     if( values.phone.length < 7  ) {
      notification.open({
         message: <h3> Phone number is not valid </h3>,
      });
      playWarningSound();
      setLoginLoading(false);
      return false;
     }

      /*---------------------------------
      Check login access
      Collect user information using token and phoen number
      ----------------------------------*/ 
      api.get(`/?token=${values.token.trim()}&phone=${values.phone.trim()}`, res => {
         if( res.code === 200 && res.body !== null) {
            if( res.body.status === 'pending' ) {
              
                // creating a e-tag
                  let edata = {
                     endpoint: '?etag',
                     body: []
                  }


                  // get the etag 
                  api.post(edata, etagres => {
                  if( etagres.code !== 202 ) {
                        
                     notification.open({
                           message: <h2> Please try again </h2>,
                           description: <p> Someone is trying to schedule now. Please try again</p>
                        });
                        playWarningSound();
                        setLoginLoading(false);
                        return false;
                  
                     } else {

                        res.body.etag = etagres.body;
                        setUserDetails(res.body); // save user information
                        setToken(values.token.trim()); // save token id
                        setLogin(true)
                     }
               })

               
               
            } else {
               notification.open({
                  message: <h4> This appointment already expired.</h4>,
                });
                playWarningSound();
            }

         }else {
            invalidNotification();
         }

      })
      


   }; // end of on submit


/**
 * 
 * 
 * Timeslot management
 * 
 * 
 */

 useEffect(()=>{
   if( !userDetails.duration ) {
      return false;
   }

   if( !timeslot[userDetails.duration][userDetails.barber_username] ) {
      setTimeSlot( prevState => {
         prevState[userDetails.duration][userDetails.barber_username] = {}
         return { ...prevState }
      } )
   }
   
   // return if the timeslot already available
   if( timeslot[userDetails.duration][userDetails.barber_username].hasOwnProperty( userDetails.date )  ) {
       /**
        * 
        * 
        * Check break time with available timeslot
        * 
       */
       const data = onlyAvailableTimeSlot({
           type: userDetails.barber_username,
           time: userDetails.duration,
           slot: timeslot[userDetails.duration][userDetails.barber_username][userDetails.date],
           weeklyoff: weeklyOff[userDetails.barber_username],
           date: userDetails.date ,
           dayoff,
           breakTime,
       })
   
       setAvailableSlot( data )
   /**
    * 
    * 
    * If teh date is not dayoff, fetch all the available timeslot for the selected date
    * 
    */
   } else {
      setSlotLoading(true);

      api.get(`/?timeslot&b=${userDetails.barber_username}&d=${userDetails.date}`, res => {

         setSlotLoading(false);
           if( res.code === 200 ) {

               let response = !Array.isArray(res.body) ? Object.values(res.body) : res.body;

               if( response.length ) {

                   /**
                    * 
                    * Store the timeslot 
                    * 
                    */ 
                   setTimeSlot( prevState => {
                       prevState[userDetails.duration][userDetails.barber_username] = {
                           ...prevState[userDetails.duration][userDetails.barber_username],
                           [userDetails.date] : response
                       };
                       return prevState
                   } );
                   /**
                    * 
                    * 
                    * Check break time with available timeslot
                    * 
                   */
                   const data = onlyAvailableTimeSlot({
                        type: userDetails.barber_username,
                        time: userDetails.duration,
                        slot: timeslot[userDetails.duration][userDetails.barber_username][userDetails.date],
                        weeklyoff: weeklyOff[userDetails.barber_username],
                        date: userDetails.date ,
                        dayoff,
                     breakTime,
                   })
                   setAvailableSlot( data )

               } 
          
           }  else {
               playWarningSound();
               notification.open({
                   message: <h3>Something went wrong!</h3>,
                   description: <p>Please try again or contact Big Apple Barbers</p>
               });
           }
      }) // end of api call
   }

}, [userDetails]) //end of use effect


/*--------------------------------
    Submit reschedule appointment
---------------------------------*/ 

const cancelHandler = () => {
   window.location.href = 'https://bigapplebarbershop.com/';
}

const submitRescheduleHandler = () => {

   setUpdateLoading(true);

   let service = serviceData.filter( x => x.name === userDetails.service_name );
   if( service[0] ) {
      userDetails.price = getReschedulePrice(userDetails, service[0], priceVariation);
   }


   // check if the date is a dayoff
   if( !availableSlot.available ) {
      notification.open({
         message: <h4> Change the date </h4>,
         description: <p> {userDetails.date} is a dayoff for  { userDetails.barber_name } </p>
       });
       playWarningSound();
       setUpdateLoading(false);
       return false;
   } 


   if ( !availableSlot.slot.includes( userDetails.slot ) ) {
      notification.open({
         message: <h4> Change the date or time </h4>,
         description: <p> {userDetails.time} is not available for  { userDetails.services } </p>
       });
       playWarningSound();
       setUpdateLoading(false);
       return false;
   }

   let args = {
      endpoint: `/?token=${token}&reschedule`,
      body: userDetails
   }

   

   api.put(args, res => {
      if( res.code === 200) {
         
         // check if admin not changing the appointment
         if( origin !== 'admin' ) {
            Modal.confirm({
               icon: '',
               content: <div className="align-center"> <img className="w-100" src={thankYou}/> <h2> Reschedule Successful </h2></div>,
               cancelText: 'Close',
               onCancel: cancelHandler
             });

             playHappySound();
            
         }

         if( origin === 'admin' ) {
            notification.open({
               message: <h2> Reschedule Successful </h2>,
            });

            window.history.back();
         }

         

      } else if( res.code === 412) {

         notification.open({
            message: <h2> Login again then try </h2>,
            description: <p> Someone is trying to schedule now. Please try again</p>
         });
         
         playWarningSound();

      } else {
         notification.open({
            message: <h4> Sorry! Could not Reschedule</h4>,
            description: <p> You may change the date, time or stylist to get a free timeslot </p>
          });
          playWarningSound();
      }

      setUpdateLoading(false);

   })

}

/*--------------------------------
   calcen appointment handler
---------------------------------*/ 

const cancelButtonHandler = () => {
   setCancelLoading(true);
   let args = {
      endpoint: `/?delete=${token}&phone=${userDetails.phone_number}`,
      body: userDetails
   }

   api.delete(args, res => {

      if( res.code === 200 ) {
         playHappySound();
          Modal.confirm({
            title: 'Delete Successful',
            icon: <ExclamationCircleOutlined />,
            content: <span className="thank-you-msg"> If you have accidentally cancelled this appointment, feel free to take an appointment once again. </span>,
            cancelText: 'Close',
            onCancel: cancelHandler
          });

      } else {
         notification.open({
            message: <h4> Something went wrong! </h4>,
            description: <p> Could not delete your appointment. Please try again </p>
          });
          playWarningSound();
      }

      setCancelLoading(false);
   })
   

      
}



   return (
      <div className="frontend-container">
         <div className="reschedule-container">
         { origin === 'frontend' ?  <> <Header />  <PageTitle title="RESCHEDULE" />  </> : null}
         { login  && userDetails &&
            <div className="reschedule-form">
               <div className="information-container">

                  <label> Change serivce </label>
                  <Select
                     placeholder="Change Service"
                     defaultValue={userDetails.service_name}
                     onSelect={ (_, opt) => {        
                        setUserDetails( prevState => {
                           prevState.duration = opt.duration;
                           prevState.price    = opt.price;
                           prevState.service_name = opt.value;
                           prevState.services = opt.title;
                           return { ...prevState }
                        } )
                     } }
                  >
                     { serviceData.map( (item, key) =>  {
                        const price = getReschedulePrice(userDetails, item, priceVariation);
                        return (
                           <Select.Option 
                              price={price} 
                              duration={item.time} 
                              title={item.title}  
                              key={key} 
                              value={item.name}> 
                                 {item.title} ({ price }) 
                           </Select.Option>
                        )

                     } )}
                  
                  </Select>

                  <label> Change Stylist </label>
                  <Select
                     placeholder="Change Stylist"
                     defaultValue={userDetails.barber_name}
                     onSelect={(_, opt) => {
                        setUserDetails( prevState => {
                           prevState.barber_username = opt.usrname;
                           prevState.barber_name = opt.name;
                           return { ...prevState }
                        } )
                     }}
                  >
                     { barberData.map( (item, key) =>  <Select.Option usrname={item.username} name={item.name}  key={key} value={item.barber_name}> {item.name} </Select.Option> ) }

                  </Select>
                  <div className="group-buttons inputs">
                        <div>
                              <label> Change Date </label>
                              <DatePicker 
                                 inputReadOnly  
                                 allowClear={false}
                                 format={dateFormat} 
                                 defaultValue={moment( userDetails.date , dateFormat)} 
                                 disabledDate={ value => {
                                    // disable previous dates
                                   if( value.format(dateFormat) === moment().format(dateFormat) || value.isAfter() ) {

                                      //disable weekends   
                                      if( weeklyOff[userDetails.barber_username]?.includes( value.day() + 1 ) ) return true;
                                      
                                      if( dayoff && dayoff[userDetails.barber_username]?.includes( value.format( dateFormat ) ) ) {
                                         return true;
                                      }
                                      // //disable global dayoff
                                      if( dayoff?.all?.includes( value.format( dateFormat ) ) ) return true;
                                      //everyday break time isFULL
                                      if( breakTime && breakTime[userDetails.barber_username] && breakTime[userDetails.barber_username].everyday && breakTime[userDetails.barber_username].everyday[0]?.slot.length === 19 ) return true;
                                      // disable when current date is true but time slot has passed
                                      if( value.format(dateFormat) === moment().format(dateFormat) && currentTime() >= 19) return true;
        
                                   } else {
                                      return true
                                   }
                                } }
                                onChange={ value => {
                                   setUserDetails( prevState => {
                                    prevState.date = value.format( dateFormat );
                                    return { ...prevState }
                                   } )
                                } }
                              />
                        </div>
                        <div>
                              <label> Change Time </label>
                              <Select
                                 placeholder="Change Date"
                                 loading={slotLoading}
                                 disabled={slotLoading}
                                 defaultValue={ userDetails.time }
                                 onSelect={val => {
                                    setUserDetails(prevState => {
                                       prevState.slot = val;
                                       prevState.time = slotToTime(val);
                                       return { ...prevState }
                                    })
                                 }}
                              >
                                 
                                { availableSlot.slot.map( item => <Select.Option  key={item} value={item}> { slotToTime(item) } </Select.Option> ) }
                                 
                              </Select>
                        </div>

                  </div>

                  <div className="policy-opt-in reschedule">
                     <h3>CANCELLATION / RESCHEDULE POLICY</h3>
                     <p className="opt-in-details text-base">We kindly ask you to make any changes 3 hours prior to your scheduled appointment. If you cancel or reschedule your appointment with less than 3 hours you will be charged 50% of the total cost, as we are holding the time for you and turning away others. Please be responsible! With all respect to you.</p>

                     <Checkbox checked={readPolicy} onChange={() => setReadPolicy(!readPolicy)}> 
                        <span style={{fontSize: '16px', userSelect: 'none'}}>I agree to the policy. (Check to proceed)</span>
                     </Checkbox>
                  </div>        

                  <div className="group-buttons">
                     { origin !== 'admin' ?  
                     
                     <Button loading={cancelLoading} onClick={cancelButtonHandler} disabled={updateLoading || !readPolicy} type="danger" htmlType="submit" className="login-form-button">
                        Cancel Appointment
                     </Button> : null }
                     
                     <Button loading={updateLoading} onClick={submitRescheduleHandler}  disabled={cancelLoading || !readPolicy}  type="primary" htmlType="submit" className="login-form-button">
                        Reschedule
                     </Button>
                  </div>

               </div>
            </div>
         }

         
         {!login &&
            <div className="login-form">
               <Form
                  name="normal_login"
                  onFinish={onFinish}
               >
                  <Form.Item
                     name="phone"
                     className="email-input-group"
                     rules={[{ required: true, message: 'Please input your phone number' }]}
                  >
                     <Input type="number" min="0" prefix={ <NumberOutlined className="site-form-item-icon"/>} placeholder="Phone Number with country code" />
                  </Form.Item>

                  <Form.Item
                  name="token"
                  className="token-input-group"
                  rules={[{ required: true, message: 'Please input token ID' }]}
                  >
                  <Input
                     prefix={<LockOutlined className="site-form-item-icon" />}
                     type="text"
                     placeholder="Token ID"
                  />
                  </Form.Item>

                  <Button loading={loginLoadin} type="primary" htmlType="submit" className="login-form-button">
                     Login Now
                  </Button>
                  
               </Form>
            </div>
         }

         </div>

      </div>
   )
}

