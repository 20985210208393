import { Fragment, useState, useContext, useEffect } from "react"
import { useHistory } from 'react-router-dom';
import { notification, Button, Checkbox  } from 'antd';

import { HeaderContext } from "./api/api-context";
import Api_Request from './api/api-request';
import useSound from 'use-sound';
import warningSound from './assets/sounds/warning.mp3';
import happySound from './assets/sounds/happy_ending.mp3';
import { compareWithIndividualPrice, getVIPprice } from "./helper";

const api = new Api_Request();

export default function CheckoutDrawer({ submitData, setVisible, submitTxt, setSubmitTxt }) {

    const history = useHistory();
    const [sms_allowed, setSms_allowed] = useState(true);
    const [ readPolicy, setReadPolicy ] = useState(false);
    const [ playWarningSound ] = useSound(warningSound);
    const [ playHappySound ] = useSound(happySound);
    
    const { setTimeSlot, priceVariation, serviceOne, serviceTwo } = useContext(HeaderContext);

    useEffect(()=>{
        Object.entries(submitData).map(([key, value]) => {
            if( typeof value === 'string' ) {
                submitData[key] = value.trim();
            }
        });
    },[submitData])

    const price = compareWithIndividualPrice(
        priceVariation,
        serviceOne,
        serviceTwo
    );

    const vip_price = getVIPprice(
        priceVariation,
        serviceOne,
        serviceTwo.username,
        serviceTwo.vip_code || ''
    )
    
    const onSubmitForm = () => {

        //update button 
        setSubmitTxt({
            txt: 'Please wait',
            loading: true
        });

        submitData.sms = sms_allowed;

        if( vip_price ) {
            submitData.vip_user = 'yes';
            submitData.price = vip_price;
            submitData.vip_code = serviceTwo.vip_code
        } else {
            submitData.vip_user = 'no';
            submitData.price = price;
            submitData.vip_code = '';
        }

        // prepare data
        let data = {
            endpoint: '?appointment',
            body: [submitData]
        }

        api.post(data, res => {

            setTimeout(()=> {

                if( res.code === 200 ) {
                history.push('/thankyou', submitData);
                playHappySound();

                } else if(res.code === 406) {
                notification.open({  
                    message: <h2> Sorry! Appointment can not be accepted </h2>,
                    });
                    playWarningSound();
                }

                else if (res.code === 412) {
                notification.open({  
                    message: <h2> Someone is trying to schedule now. Please try again </h2>,
                    });
                    playWarningSound();
                }
    
                else {
                notification.open({
                    message: <h4>Sorry! Could Not Suubmit your appoinment for { submitData.date } at { submitData.time } Please change your date or time </h4>,
                    });
                    playWarningSound();
                }
                
            
                /**
                 * 
                 * Reset The tiemslot 
                 * 
                 */ 
                setTimeSlot( prevState => {
                    prevState = {
                        30 : {},
                        60 : {}
                    }
                    return prevState
                } );
                setSubmitTxt({
                    txt: 'Submit Again',
                    loading: false
                });
                setVisible(false)

            }, 1000)
            
        })

   }


    return (
        <Fragment>
            <div className="checkout-container">

                <table>
                    <tbody>
                        <tr>
                            <td>Service:</td>
                            <td> {submitData.services}  </td>
                        </tr>
                        <tr>
                            <td>Stylist:</td>
                            <td> {submitData.barber_name}  </td>
                        </tr>
                        <tr>
                            <td>Price:</td>
                            <td> 
                                <div className={vip_price ? 'vip-price': 'no-vip'}>
                                    <span>{price}</span>
                                    {vip_price && (
                                        <span>{vip_price} for VIP</span>
                                    )}
                                </div>
                            </td>
                        </tr>
                        
                        <tr>
                            <td>Email:</td>
                            <td> {submitData.email_address}  </td>
                        </tr>
                        <tr>
                            <td>Phone:</td>
                            <td> +{submitData.phone_number}  </td>
                        </tr>
                        <tr>
                            <td>Name:</td>
                            <td> {submitData.first_name} {submitData.last_name}  </td>
                        </tr>
                        <tr>
                            <td>Date:</td>
                            <td> {submitData.date}</td>
                        </tr>
                        <tr>
                            <td>Time:</td>
                            <td> {submitData.time}</td>
                        </tr>
                        <tr>
                            <td>Duration:</td>
                            <td> {submitData.duration === 30 ? '30 Minutes ' : '1 Hour'}</td>
                        </tr>
                        {/* <tr>
                            <td>SMS:</td>
                            <td> {sms_allowed ? 'Yes' : 'No'}</td>
                        </tr> */}
                    </tbody>
                </table>
         </div>


         <div className="text-opt-in">
            <Checkbox name="sms_allowed" checked={sms_allowed} onChange={() => setSms_allowed(!sms_allowed)}> Notify me with text message. </Checkbox>
            <p className="opt-in-details"> Big Apple Barbers will send you a text message when your appointment is ready and notify you before the appointment time.</p>
         </div>
         
         <div className="policy-opt-in">
            <h3>CANCELLATION / RESCHEDULE POLICY</h3>
            <p className="opt-in-details text-base">We kindly ask you to make any changes 3 hours prior to your scheduled appointment. If you cancel or reschedule your appointment with less than 3 hours you will be charged 50% of the total cost, as we are holding the time for you and turning away others. Please be responsible! With all respect to you.</p>

            <Checkbox checked={readPolicy} onChange={() => setReadPolicy(!readPolicy)}> 
                <span style={{fontSize: '16px', userSelect: 'none'}}>I agree to the policy. (Check to proceed)</span>
            </Checkbox>
         </div>
        
        <div style={readPolicy ? {} : {
            opacity: 0.5,
            pointerEvents: 'none',  
        }}>
            <Button disabled={submitTxt.loading || !readPolicy} className="submit-btn" onClick={onSubmitForm} type="primary" loading={ submitTxt.loading }> { submitTxt.txt } </Button>
        </div>

        </Fragment>
    )
}