import {useState, useEffect, useRef} from 'react';
import { Table, Input, Button, Space, Modal } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import {Loading} from './../utils';
import Api_Request from './../api/api-request';
const api = new Api_Request();



const ClientDatabase = () => {
    const [ pageLoaded, setPageLoaded ] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [c, setC] = useState(0);

    let searchInput = useRef();



    useEffect(()=>{
        syncInfo();
    },[])

    const syncInfo = () => {

        api.get(`/?clientprofile`, res =>{
            if( res.code === 200 ) {
                setPageLoaded(true)
                const dd = res.body.map( (item, key) => {
                    return {
                        ind: item.ind,
                        phone: item.phone,
                        details: <div key={key}>
                            <p><strong>Date</strong>: { item.date }</p>
                            <p><strong>Name</strong>: { item.name }</p>
                            <p><strong>Barber</strong>: { item.barber }</p>
                            <hr/> <br/>
                            <p> {item.des} </p>
                            <div className="database-image-preview">
                                {item.photos?.map((p, k) => <div key={k + key}> <img onClick={()=>showModal(p)} src={p}/></div>)} 
                            </div>

                            <br/>
                            <Button onClick={()=> deleteClientProfile(item.ind)} loading={loading} type="primary" danger>Delete</Button>
                        </div>
                    }
                } );
                setData(dd)
            }
         })
    }

    const deleteClientProfile = ind => {
        setLoading(true)
        let args = {
            endpoint: `/?clientprofile=${ind}`,
            body: ''
         }
      
         api.delete(args, res => {
            if( res.code === 200 ) {
                syncInfo()
            }
         })
    }


    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
            <Input
            ref={node => {
                searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
            <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
            >
                Search
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
            </Button>
            <Button
                type="link"
                size="small"
                onClick={() => {
                confirm({ closeDropdown: false });
                setSearchText(selectedKeys[0]);
                searchedColumn(dataIndex);
                }}
            >
                Filter
            </Button>
            </Space>
        </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
        record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
        if (visible) {
            setTimeout(() => searchInput.select(), 100);
        }
        },
        render: text =>
        searchedColumn === dataIndex ? (
            <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
            />
        ) : (
            text
        ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex)
    };

    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('');
    };

    const columns = [
        {
            title: 'phone',
            dataIndex: 'phone',
            key: 'phone',
            width: '30%',
            ...getColumnSearchProps('phone'),
        },
        {
            title: 'Details',
            dataIndex: 'details',
            key: 'details'
        },
    ];


    // show photo in bigger size
    const showModal = (i) => {
        setC(i);
        setIsModalVisible(true);
    };

    return (
        <div>
            {!pageLoaded ? <Loading/> : <>
            
            <Table columns={columns} dataSource={data} />

            <Modal 
                visible={isModalVisible} 
                className="ashraf-see-photos"
                onOk={()=> setIsModalVisible(false)} 
                onCancel={()=> setIsModalVisible(false)}>
                <img src={c}/>
            </Modal>
            </> }
        </div>
    );
}



export {ClientDatabase}