import { useState, useContext, Fragment, useEffect } from 'react';
import { Drawer, Button, notification } from 'antd';
import { makeDate, onlyAvailableTimeSlot, Loading } from '../../utils';
import { HeaderContext } from './../../api/api-context';

import View from './view';
import AddEdit from './add-edit';
import AddNewButton from './add-new-button';
import TimeSlot from './timeslot';
import Accordion from './accordion';
import Context from './context';
import Api_Request from './../../api/api-request';

const api = new Api_Request();

/*============================
React Component start
=============================*/ 

export default function BreakTime() {

    const { dateFormat, allBarber, dayoff, breakTime, setBreakTime } = useContext(HeaderContext)
   /**
    * 
    * React States 
    * 
    */ 
    const [ disableSlot, setDisableSlot ] = useState(false);
    const [ isPageLoaded, setIsPageLoaded ] = useState(false);
    const [ action, setAction ] = useState('add');
    const [ visible, setVisible ] = useState(false);
    const [ freeSlot, setFreeSlot ] = useState({});
    const [ breakTimeState, setBreakTimeState ] = useState({
        type: 'all',
        mode: 'custom',
        time: []
    })

    /**
     * 
     * 
     * Check weather the page loaded or not 
     * 
     */ 
    useEffect(()=>{
        if( 
            Object.keys(allBarber).length > 1 && 
            dayoff && breakTime
        ) {
            setIsPageLoaded(true)
        }
    },[allBarber, dayoff, breakTime])

    /**
    * 
    * 
    * Check Booked Timeslot  
    * 
    */ 

    const getBookedSlot = ( username, date, index = false ) => {
        
        setDisableSlot(true);

        api.get(`/?timeslot&b=${username}&d=${date}`, res => {

            setDisableSlot(false);

            if( res.code === 200 ) {

                let response = !Array.isArray(res.body) ? Object.values(res.body) : res.body;
                if( response.length && allBarber[username] ) {

                    const data = onlyAvailableTimeSlot({
                        type: username,
                        weeklyoff: allBarber[username].weeklyOff,
                        slot: response,
                        time: 30,
                        date,
                        dayoff,
                        breakTime,
                    })

                    setFreeSlot(prevState => {
                        
                        if( !prevState[username] ) {
                            prevState[username] = {}
                        }

                        // it will work on when it's edit mode and accordion panel expanded 
                        let editorModeSlot = [];
                        if( index && action === 'edit' ) {
                            editorModeSlot = breakTimeState.time[index].slot;
                        }

                        prevState[username] = {
                            ...prevState[username],
                            [date] : [...data.slot, ...editorModeSlot],
                        }

                        return {...prevState}
                    }); 

                }
            }
        })
    }

    /**
     * 
     * 
     * Submit Break Time 
     * 
     * 
     */ 
     const onSubmitHandler = () => {
        setDisableSlot(true)

        const { type, mode, time } = breakTimeState;

        const args = {
            endpoint: '?break-time',
            body: breakTimeState
        }
        
        api.post(args, response => {

            if( response.code === 202 ) {
                
                notification.open({
                message: <h3> Successful </h3>,
                });

                setBreakTime( prevState => {
                    if( !prevState[type] ) prevState[type] = {}
    
                    if( prevState[type] ) {
                        prevState[type][mode] = time;
                    }
    
                    return {...prevState};
                    
                })

            } else {
                notification.open({
                message: <h3> Failed! </h3>,
                });
            }

            setDisableSlot(false);
            setVisible(false);

        })

          
    }

    
    const contextValue = { action, setAction, breakTimeState, setBreakTimeState, disableSlot, setDisableSlot, setVisible, freeSlot, getBookedSlot, onSubmitHandler }

    /**
     * 
     * 
     * Return JSX 
     * 
     * 
     */ 
    return <Context.Provider value={contextValue}>
        <div className="admin-break-time-page">
            <h2> Break Time Settings </h2>
            <hr/> 
            <br/>
            { !isPageLoaded ? <Loading/> :
                <Fragment>
                    <View/>
                    <div className="dayoff-footer">
                    <Button
                        type="primary" 
                        size="large" 
                        onClick={ () => {
                            setBreakTimeState( prevState => {
                                prevState.type = 'all';
                                prevState.mode = 'custom';
                                prevState.time = [];
                                return { ...prevState };
                            })
                            setAction('add');
                            setDisableSlot(false);
                            setVisible(true);
                        }}
                    > Add Break Time 
                    </Button>
                    </div>
                    

                    <Drawer
                    closable
                    title="Create Break Time"
                    className="admin-break-time-drawer"
                    placement="right"
                    visible={visible}
                    onClose={() => setVisible(false)}
                    >
                        <AddEdit>

                            {
                            /**
                             * 
                             * 
                             * 
                             * Show Accordion when type = custom or weekly
                             * 
                             * 
                             */ 
                            ['custom', 'weekly'].includes(breakTimeState.mode) && 
                            <Accordion>
                                <AddNewButton
                                    disabled={disableSlot}
                                    onClick={ () => { 
                                        setBreakTimeState( prevState => {
                                            const date = prevState.mode === 'weekly' ? makeDate().day() : makeDate().format(dateFormat);
                                            
                                            if( prevState.mode === 'custom' && prevState.type !== 'all' ) {
                                                getBookedSlot( prevState.type, date );
                                            }

                                            breakTimeState.time.push({
                                                date: date,
                                                note: '',
                                                slot: []
                                            })                                    
                                            return { ...prevState }

                                        })
                                    }}
                                    > Add new {breakTimeState.mode} item 
                                </AddNewButton>
                            </Accordion>
                            }

                            { 
                            /**
                             * 
                             * 
                             * 
                             * Show Timeslot when type = everyday 
                             * 
                             * 
                             */ 
                            breakTimeState.mode === 'everyday' &&  <TimeSlot/> }

                        </AddEdit>

                    </Drawer>
                </Fragment>
            }
        </div>  
    </Context.Provider> 
}
