import { useEffect, useState, useContext } from 'react';
import { Drawer, Button, Form, Input, Radio, Upload, notification, Row, Col, Switch } from 'antd';
import {Loading} from './../utils';
import {HeaderContext} from './../api/api-context';
/** 
@method getBase64 used to convert user uploaded photo to base64 codeo so that it can be sent to server easily 
*/
 
import { getBase64 } from './helper';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import Api_Request from './../api/api-request';
import useSound from 'use-sound';
import warningSound from './../assets/sounds/warning.mp3';

const api = new Api_Request();

// Admin Service Component
const AdminServices = () => {

   const [visible, setVisible] = useState(false); /** @const visible used to open/code drware  */
   const [loginLoading, setLoginLoading] = useState(false) /** @const loginLoading loading icon for button  */
   const [fileList, setFileList] = useState([]) /** @const fileList contains uploaded image data  */
   const [loading, setLoading] = useState(false); /** @const loading contains delete button loading  */
   const [playWarningSound] = useSound(warningSound);
   const [photo, setPhoto] = useState('');
   const [pageLoaded, setPageLoaded] = useState(false);
   const [ store, setStore ] = useState({}); 
   const [editMode, setEditMode] = useState({
      enable: false,
      id: null
   });

   const { serviceData, setServiceData, barberData } = useContext( HeaderContext );
   const [form] = Form.useForm();

   useEffect(()=>{
      if( !serviceData.length ) {
         api.get('/?services', res => {
            if( res.code === 200 && res.body.content !== null) {
               setServiceData(res.body.content);
               setPageLoaded(true)
            }
         })
      } else {
         setPageLoaded(true)
      }
      setEditMode({
         enable: false,
         id: null
      });
   },[])


   /*---------------------------
   Upload Image on server
   -----------------------------*/ 
   const uploadImage = async f => {

      getBase64(f).then(res =>{
         api.upload({
            file: {
               name: f.name.replaceAll(" ", '_'),
               base: res,
            },
            action: 'upload'
         }, callback => {
            
            if( callback.code === 409 || callback.code === 202 ) {
               setPhoto(`${api.apiRoot}upload/${f.name}`);
               setFileList([
                  {
                     uid: '-2',
                     name: f.name,
                     status: 'done',
                     url: photo,
                  }
               ])
            } else {
               notification.open({
                  message: <h3> Sorry. Can not create a service </h3>,
                });
               playWarningSound();
            }

            setLoading(false);
         })
      })
      
      
   }
   
   
   /*---------------------------
   Delete Service list
   -----------------------------*/ 
   const deleteServiceHandler = (key, name) => {
      setLoginLoading(true)
      const nd = [...serviceData];
      nd.splice(key, 1);

      let data = {
         endpoint: '?services',
         body: nd
      }

      api.post(data, res => {
         setLoginLoading(false)
         if( res.code === 202 ) {
            setServiceData(res.body);
         } else {
            notification.open({
               message: <h3> Sorry Can Delete </h3>,
             });
            playWarningSound();
         }

         setLoginLoading(false)
      })
      
   }

   /**
    * 
    * 
    * edit service 
    * 
    */ 
   const editServiceHandler = (key, name) => {
      form.resetFields();
      setEditMode({
         enable: true,
         id: key
      });
      form.setFieldsValue({...serviceData[key]});
      setStore({...serviceData[key]});
      setVisible(true);
   }
   
   /*---------------------------
   On Submit Form
   -----------------------------*/ 
   const onFinish = (values) => {
      
      /**
       * 
       * 
       * run script for edit mode 
       */ 
      if( editMode.enable ) {
         const editedData = { ...serviceData[editMode.id], ...values, name: values.title.replaceAll(' ', "_") }
         setServiceData( prevState => {
            prevState[editMode.id] = editedData;


            let data = {
               endpoint: '?services',
               body: prevState
            }
   
            api.post(data, res => {
               setLoginLoading(false)
               if( res.code === 202 ) {
                  notification.open({
                     message: <h3> Successful </h3>,
                  });
                  setVisible(false)
               } else {
                  notification.open({
                     message: <h3> Sorry Can not create new service </h3>,
                  });
                  playWarningSound();
               }
            })

            return [...prevState]
         })


         return;
      }

      /**
       * 
       * 
       * run script for creating a new service 
       */ 
      if( photo.length ) {
         setLoginLoading(true)
         values.name = values.title.replaceAll(' ', "_");
         values.photo = photo;

         let data = {
            endpoint: '?services',
            body: [...serviceData, values]
         }

         api.post(data, res => {
            setLoginLoading(false)
            if( res.code === 202 ) {
               setServiceData(res.body);
               setVisible(false)
            } else {
               notification.open({
                  message: <h3> Sorry Can not create new service </h3>,
                });
               playWarningSound();
            }
         })
         
      } else {
         notification.open({
            message: <h3> Upload Photo </h3>,
          });
         playWarningSound();
      }
   }


   const showDrawer = () => {
      setEditMode({
         enable: false,
         id: null
      })
      form.resetFields();
      setStore({});
      setLoginLoading(false)
      setFileList([]);
      setPhoto('');
      setVisible(true)
   };

   const handleChange = ({ fileList }) => {
      setPhoto('');
      setLoginLoading(false)
      setFileList(fileList)
   };

  
   const renderService = (item, key) => {
      return (
      <div key={key} className="service-item"> 
         <div className="service-item-image">
            <img src={item.photo} alt="service photo"/>
         </div>
         <div className="service-item-content">
            <p><strong>Name:</strong><span>{item.title}</span></p>
            <p><strong>Price:</strong><span>{item.price}</span></p>
            <p><strong>Duration:</strong><span>{item.time} Mins</span></p>
            <p className='group-btns'> 
               <Button loading={loginLoading} onClick={()=> deleteServiceHandler(key, item.name)} type="primary" danger> Delete </Button>  
               <Button onClick={()=> editServiceHandler(key, item.name)} type='primary'>Edit</Button>
            </p>
         </div>
      </div>)
   }

   const uploadButton = () => (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
   );



   return (

      <> {!pageLoaded ?  <Loading/> : 
      
      <div className="admin-service-container">
          <div className="service-showcase">
               {serviceData.map((item, key) => renderService(item, key))}
          </div>
          <div className="add-new-service">
               <Button size="large" type="primary" onClick={showDrawer}>Add a New Service</Button>
          </div>

         <Drawer
            title={editMode.enable ? "Edit This Service" : "Add A New Service" }
            className="admin-service-drawer"
            placement="right"
            closable={true}
            onClose={() => setVisible(false)}
            visible={visible}
         >

            <Form
               name="service-form"
               className="service-form"
               form={form}
               initialValues={{ remember: false }}
               onFinish={onFinish}
               layout="vertical"
               onFieldsChange={(_, allFields) => {
                  const data = allFields.reduce((all, current) => {
                     const prop = current.name[0];
                     return {
                        ...all,
                        [prop]: current.value
                     }
                  }, {});

                  setStore( data );

               }}
            >
            <Form.Item label="Service Name"  name="title" rules={[{ required: true, message: 'Enter Service Name' }]} >
               <Input />
            </Form.Item>

            <Form.Item label="Duration" name="time" rules={[{ required: true, message: 'Select a duration for this service' }]}>
               <Radio.Group
                  options={[
                     { label: '60 Minitues', value: 60 },
                     { label: '30 Minitues', value: 30 },
                   ]}
                  optionType="button"
               />
            </Form.Item>

            <Form.Item label="Default Price">
               <Row gutter={16}>
                  <Col span={12}>
                     <Form.Item name="price" rules={[{ required: true, message: 'Enter default price' }]} >
                        <Input/>
                     </Form.Item>
                  </Col>
                  <Col span={12}>
                     <Form.Item name="individual_price" valuePropName="checked" >
                        <Switch unCheckedChildren="MORE"/>
                     </Form.Item>
                  </Col>
               </Row>
            </Form.Item>

            {store.individual_price && (
               <div style={{background: '#eee', padding: '10px', marginBottom: '20px', marginTop: '-30px'}}>
                  {
                     (barberData || []).map( item => (
                        <Form.Item label={"Default Price for " + item.name}  name={item.username + "_default_price"} key={item.username}>
                           <Input/>
                        </Form.Item>
                     ))
                  }
               </div>
            )}
            

            <Form.Item label="VIP Price">
               <Row gutter={16}>
                  <Col span={12}>
                     <Form.Item name="vip_price">
                        <Input />
                     </Form.Item>
                  </Col>
                  <Col span={12}>
                     <Form.Item name="individual_vip_price" valuePropName="checked" >
                        <Switch unCheckedChildren="MORE"/>
                     </Form.Item>
                  </Col>
               </Row>
            </Form.Item>
            
            {store.individual_vip_price && (
               <div style={{background: '#eee', padding: '10px', marginBottom: '20px', marginTop: '-30px'}}>
                  {
                     (barberData || []).map( item => (
                        <Form.Item label={"VIP Price for " + item.name}  name={item.username + "_vip_price"} key={item.username}>
                           <Input/>
                        </Form.Item>
                     ))
                  }
               </div>
            )}
            
            
            { !editMode.enable && <Form.Item label="Image">
               <Upload
                  action={uploadImage}
                  listType="picture-card"
                  fileList={fileList}
                  previewVisible={true}
                  beforeUpload={() => setLoading(true)}
                  onChange={handleChange}
               >
                  {fileList.length >= 1 ? null : uploadButton()}
               </Upload>
            </Form.Item> }
            
            <Form.Item>
               <Button size="large" loading={loginLoading}  type="primary" htmlType="submit" className="login-form-button">
                   { editMode.enable ? 'Update Now' : 'Create Now' }
               </Button>
            </Form.Item>
            </Form>

         </Drawer>
      </div> }

      </>
   )
}


export {AdminServices};