import { useState, useEffect } from 'react';
import { Header, Loading } from './utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import Api_Request from './api/api-request';
import userImage from './assets/images/user.png';
import yelpLogo from './assets/images/yelp.png';
import {setCookie, getCookie} from './admin/helper';

const api = new Api_Request();

export default function Reviews() {

   let now = Date.now();
   let end = now + 1800000; // one day : 86400000

   const [yelp, setYelp ] = useState([]);
   const [profile, setProfile ] = useState([]);
   const [load, setLoad ] = useState(true);

   let endID = getCookie('yelp_api_end');
   let yelp_data = getCookie('yelp_data');
   let yelp_profile = getCookie('yelp_profile');

   useEffect(()=>{

      if( endID && yelp_data && yelp_profile) {
         endID = parseInt(endID)

         if( now > endID ) {
         // if( true ) {

            api.yelp_reviews(data => {
               const {profile, reviews}  = data.body;
               if( data.code === 200 ) {
                  setYelp(JSON.parse(reviews));
                  setCookie('yelp_data',reviews, 1 );
                  setProfile(JSON.parse(profile));
                  setCookie('yelp_profile', profile, 1);
                  setLoad(false)
               }
            });
         
         } 

         else {

            let saved_data = getCookie('yelp_data');
            setYelp(JSON.parse(saved_data));

            let saved_profile = getCookie('yelp_profile');
            setProfile(JSON.parse(saved_profile));
            setLoad(false)

         }

      } else {

         api.yelp_reviews(data => {
            const {profile, reviews}  = data.body;
            if( data.code === 200 ) {
               setYelp(JSON.parse(reviews));
               setCookie('yelp_data',reviews, 1 );
               setProfile(JSON.parse(profile));
               setCookie('yelp_profile', profile, 1);
               setLoad(false)
            }
         });
         
         setCookie('yelp_api_end', end, 1);

      }

   },[0])


   const star = rating => {
      const d = [1,2,3,4,5]
      return(
         <div className="yel-stars">
            <div className="inactive">
               {d.map((i, k) => <FontAwesomeIcon  key={k} icon={faStar} />)}
            </div>
            <div className="active">
               {d.map((i, k) => i <= rating ? <FontAwesomeIcon  key={k} icon={faStar}/> : null )}
            </div>
           
         </div>
      )
   } 


   const reviewer = (item) => {
      return (
         <div className="yelp-reviewer" key={item.id}>
               <div className="yelp-reviewer__head"> 
                  <div className="reviewer-image">
                     {item.user.image_url !== null ? <img src={ item.user.image_url } alt={item.user.id} /> : <img src={ userImage } alt={item.user.id} />  }
                     <img className="yelp-logo" src={yelpLogo} alt="yelp logo"/>
                  </div>
                  
                  <div className="yelp-reviewer-content">
                        <a className="reviewer-name" target="_blank" href={item.user.profile_url}> {item.user.name} </a>
                        <div>
                           <div className="reviewer-rating"> {star(item.rating)} </div>
                           <div className="reviewer-no"> {item.rating}.0 </div>
                        </div>
                  </div>
               </div>

               <span className="review-date"> {item.time_created} </span>

               <div className="review-text">
                  <p> 
                     { item.text } 
                     <a href={item.url} target="_blank"> Read More </a>
                  </p>
                  
               </div>

         </div>
      )
   }


   return (
      <div className="frontend-container reviews-page">
         <Header/>
         {load ?  <Loading/> : 
        
         <div className="yelp-review">
            <div className="yelp-review__header">
               <h2 className="star_count">{ profile.rating }</h2>
               {star(profile.rating)}
               <p className="based_on"> Based on {profile.review_count} reviews </p>
            </div>
            <div className="yelp-review__content">
               {yelp?.reviews?.map( item => reviewer(item) )}
               <div className="yelp-review__footer">
                  <a className="write_review" href="https://www.yelp.com/writeareview/biz/azwljAJbybf1zH6XJjnzrA?return_url=%2Fbiz%2FazwljAJbybf1zH6XJjnzrA&source=biz_details_war_button" target="_blank"> Write A Review </a>
                  <a className="profile_btn" href={profile.url} target="_blank"> Visit Profile </a>
                </div>
            </div>
         </div>
         }
      </div>
   )
}


export {Reviews};