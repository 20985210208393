import { useContext, Fragment } from 'react';
import { Checkbox, Switch, Input } from 'antd';
import { slotset, slotToTime } from './../../utils'; 
import Context from './context';

/**
 * 
 * 
 * Timeslot component for break time 
 * 
 * 
 */ 
export default function TimeSlot() {


    const { disableSlot, breakTimeState, setBreakTimeState } = useContext(Context)

    return <Fragment> 

        <div className="break-time-layout__item">
            <label className="label"> Select Break Time Slots</label> <br/>
            <Switch
                checkedChildren="Enable All"
                disabled={disableSlot}
                unCheckedChildren="Disable All"
                checked={ breakTimeState.time[0].slot.length === 19  }
                onChange={ value => {
                    setBreakTimeState( prevState => {  
                        prevState.time[0].slot = value ? Array.from( slotset ) : [];
                        return {...prevState}
                    } )
                } }  
            />
            <br/>
            <br/>
            <div className="content">
                <Checkbox.Group 
                    value={breakTimeState.time[0].slot} 
                    style={{ width: '100%' }} 
                    className="timeslot-checkbox"
                    disabled={disableSlot} 
                    onChange={val => {
                        setBreakTimeState( prevState => {  
                            prevState.time[0].slot = val
                            return {...prevState}
                        })
                    }}
                >
                    {
                        Array.from(slotset).map( item => <Checkbox key={item} value={item}> { slotToTime(item) } </Checkbox> )
                    }
                </Checkbox.Group>
            </div>
        </div>  

        <div className="break-time-layout__item">
            <label className="label"> Write a note </label>
            <div className="content">
                <Input.TextArea rows={3}
                    value={breakTimeState.time[0].note}
                    onChange={ev => {
                        setBreakTimeState( prevState => {  
                            prevState.time[0].note = ev.target.value
                            return {...prevState}
                        })
                    }}
                />
            </div>
        </div> 

    </Fragment>
}