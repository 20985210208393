
import {Link} from 'react-router-dom';
import loading from './assets/images/loading.gif';
import white_logo from './assets/images/white-logo.png';
import moment from 'moment';

const onlyAvailableTimeSlot = ( options ) => {
   //{ type, time, slot, date, breakTime, dayoff }
   const config = Object.assign( JSON.parse(JSON.stringify(options)), {

      dateFormat : 'M-D-YYYY',

      deleteAlreadyPassedTime: function() {
         this.slot.forEach( slot => {
            if( currentTime() > time24(slot) && moment().format( 'M-D-YYYY' ) === this.date ) {
               this.slot.delete( slot )
            } 
         } )
      },

      deleteSlot: function( breakSlot, target ) {
           breakSlot.map( item => {
               if( item.date === target ) {
                   item.slot.map( disabledSlot => {
                       this.slot.delete(disabledSlot)
                   } )
               }
           })
      },

      findEveryday:function( breakSlot ) {
           breakSlot[0].slot.map( disabledSlot => {
               this.slot.delete(disabledSlot)
           })
      },

      findWeekly:function( breakSlot ) {
           const currentWeek = makeDate( this.date ).day();
           this.deleteSlot( breakSlot, currentWeek );

      },

      findCustom:function( breakSlot ) {
           this.deleteSlot( breakSlot, this.date );
      },

      findMatch: function( type ) {
           if( this.breakTime[type].everyday ) this.findEveryday( this.breakTime[type].everyday )
           if( this.breakTime[type].weekly ) this.findWeekly( this.breakTime[type].weekly )
           if( this.breakTime[type].custom ) this.findCustom( this.breakTime[type].custom )
      },

      breakDown60: function( max = 19 ) {
            this.slot.delete( 'slot_19' )
            this.slot.forEach( slot => {
               let suffix = parseInt( slot.split('_')[1] );
               const prefix = slot.split('_')[0]; 
               suffix++
               if( suffix > 0 && suffix <= max ) {
                  const nxtSlot = prefix + '_' + suffix;
                  if( !this.slot.has( nxtSlot ) ) {
                     this.slot.delete( slot )
                  } else {
                     this.slot.delete( nxtSlot )
                  }
               }
            })
      },

      dayoffHandler: function( type ) {
         
         const currentDate = makeDate(this.date)
         // check is the date already gone
         if( this.date === moment().format(this.dateFormat) || currentDate.isAfter() ) {
           
            // // disable specific dayoff
            if( this.dayoff[type].includes( currentDate.format( this.dateFormat ) ) ) {
               this.slot.clear();
               return false;
            }

         } else {
            this.slot.clear()
         }
      },

      init: function() {
            const currentDate = makeDate(this.date);

            this.weeklyoff = this.weeklyoff || [];
            this.slot      = this.slot ? new Set( this.slot ) : new Set([]);
            this.dayoff    = this.dayoff || {};
            this.breakTime = this.breakTime || {};

            //disable weekends   
            if( this.weeklyoff.includes( currentDate.day() + 1 ) ) {
               this.slot.clear();
               return {
                  available: false,
                  slot: [],
               }
            }

            // dayoff handler
            if( this.dayoff.all ) this.dayoffHandler( 'all' );
            if( this.dayoff[ this.type ] ) this.dayoffHandler( this.type );

            //passed time handler
            this.deleteAlreadyPassedTime();
            //break time handler
            if( this.breakTime.all ) this.findMatch( 'all' )
            if( this.breakTime[ this.type ] ) this.findMatch( this.type );
            // one hour slot handler
            if( this.slot.size > 0 && this.time === 60 ) this.breakDown60();
            
            if( this.slot.size === 0 ) {
                  return {
                     available: false,
                     slot: [],
                  }
            }
            return {
                  available: true,
                  slot: Array.from( this.slot ),
            }
      }
       
   } )
   
   return config.init();

}

/**
 * 
 * 
 * Convert into date 
 * format: 12-30-2021
 * on IOS device the format must be 12/30/2021
 * 
 */ 
const makeDate = (date = false, onlyDate = false) => {
   
   if( onlyDate ) {
      const ios = date.replaceAll('-', '/');
      return new Date( ios )
   }

   if( !date ) {
      return moment( new Date() );
   }

   const ios = date.replaceAll('-', '/');
   return moment( new Date( ios ) );
}

/*
   ----------------------------
   page header area
   ----------------------------
*/ 
const Header = () => {
   return (
      <div className="frontend-header">
         <img width="350" height="172" loading='lazy' src={white_logo} alt="logo" />
      </div>
   )
}


const PageTitle = (props) => {
    return (
      <div className="frontend-page-title">
         <h2>{props.title}</h2>
         <a href="http://bigapplebarbershop.com"> Return  </a>
      </div>
    )
}

/*
   ----------------------------
   home service categories
   ----------------------------
*/ 
const ServiceItem = props => {
   return (
      <Link to={ props.route } className="service-item">
         <div className="service-item--icon"> {props.icon} </div>
         <div className="service-item__content"> 
            <span className="service-item__content--title"> {props.title} </span>
            <span className="service-item__content--context"> {props.context} </span>
         </div>
      </Link>
   )
}

/*
   ----------------------------
   response alert on page
   ----------------------------
*/ 
const ResponseAlert = props => {
   if( props.code !== 200 ) {
      return(
         <div className="alert-error">
            <h4> { props.message } </h4>
            <h2> { props.code } </h2>
         </div>
      )
   }
}


/*
   -------------------------
   Next timeslot
   -------------------------
*/ 
const nextTimeSlot = (currentSlot, duration) => {

   let slots = currentSlot.split('_')[1];
   let rslt = 'slot_';
   if( duration === 60 ) {
      rslt = rslt +  (+slots + 2);
   } else {
      rslt = rslt +  (+slots + 1);
   }

   return rslt

}



/*
   ----------------------------
   slot to time convetor
   ----------------------------
*/ 
const slotToTime = slot => {

   let time = '';

      switch( slot ) {
         case 'slot_1'  : time = '10:00 AM'; break;
         case 'slot_2'  : time = '10:30 AM'; break;
         case 'slot_3'  : time = '11:00 AM'; break;
         case 'slot_4'  : time = '11:30 AM'; break;
         case 'slot_5'  : time = '12:00 PM'; break;
         case 'slot_6'  : time = '12:30 PM'; break;
         case 'slot_7'  : time = '01:00 PM'; break;
         case 'slot_8'  : time = '01:30 PM'; break;
         case 'slot_9'  : time = '02:00 PM'; break;
         case 'slot_10' : time = '02:30 PM'; break;
         case 'slot_11' : time = '03:00 PM'; break;
         case 'slot_12' : time = '03:30 PM'; break;
         case 'slot_13' : time = '04:00 PM'; break;
         case 'slot_14' : time = '04:30 PM'; break;
         case 'slot_15' : time = '05:00 PM'; break;
         case 'slot_16' : time = '05:30 PM'; break;
         case 'slot_17' : time = '06:00 PM'; break;
         case 'slot_18' : time = '06:30 PM'; break;
         case 'slot_19' : time = '07:00 PM'; break;
         case 'slot_20' : time = '07:30 PM'; break;
         case 'slot_21' : time = '08:00 PM'; break;
         default : time = ''; 
      }

   return time;
}

/*
   ----------------------------
   index to week day
   ----------------------------
*/ 
const indexToWeekDay = index => {
   let day = '';
      switch( index ) {
         case 0  : day = 'Sunday'; break;
         case 1  : day = 'Monday'; break;
         case 2  : day = 'Tuesday'; break;
         case 3  : day = 'Wednesday'; break;
         case 4  : day = 'Thursday'; break;
         case 5  : day = 'Friday'; break;
         case 6  : day = 'Saturday'; break;
         default : day = ''; 
      }

   return day;
}


const time24 = slot => {
   let slotTime = slotToTime(slot);
   let slotTime24Hours = slotTime.split(' ');
   let time = slotTime24Hours[0].replace(':','.');
   ( slotTime24Hours[1] === 'PM' && time !== '12.00' && time !== '12.30' ) ? time = parseFloat(time) + 12 : time = parseFloat(time);
   return time
}

/*
   ------------------------------
   one hour sloting method
   ------------------------------
*/ 

const oneHourSlot = (response, max) => {

   let disabledSlotArr = [];
   let x = 0;
   while( x < response.length ) {
      let suffix = parseInt(response[x].split('_')[1]);
      let prefix = response[x].split('_')[0];
          suffix++

      if( suffix > 0 && suffix <= max ) {
         
         let nxtSlot = prefix + '_' + suffix ;

         if( response.indexOf(nxtSlot) === -1 ) {
            disabledSlotArr.push(response[x]) // disable current one due to next one is empty 
            x++;
         } else {
            disabledSlotArr.push(nxtSlot) // disable next slot due to making one hour pair 
            x = x + 2;
         }
         
   } else { break; } } // enf of while

   let result = response.filter(function(obj) { return disabledSlotArr.indexOf(obj) == -1; });

   if( result.length ) {
      return result
   } else{
      return false;
   }

}

/*
   ------------------------------
   get current time
   ------------------------------
*/ 

const currentTime = () => {
   let d = new Date();
   let d2 = new Date(d)
   // add 30 mins with the current time
   if( d.getHours() < 23 ) {
      d2.setMinutes( d.getMinutes() + 30 )
   }

   let h = d2.getHours();
   let m = d2.getMinutes();
   return parseFloat(`${h}.${m}`);
}


/*
   ----------------------------
   haircut service container
   ----------------------------
*/ 
const Haircuts = props => {

   function innerComponent( item, key ) {
      return (
         <Link to="/barbers" key ={ key } onClick={()=> props.nextitem('services',item)}>
            <div className="haircut-item" >
               <div className="haircut-item--image"> 
                  <img width="239" height="277" loading='lazy' src={ item.photo }/>
               </div>
               <div className="haircut-item__content"> 
                  <span className="haircut-item__content--name"> { item.title } </span>
                  {/* <span className="haircut-item__content--price"> { item.price } </span> */}
               </div>
            </div>
         </Link>
      );     
   }

   if( props.data.length ) {
      return (
         <div className="haircut-container"> 
             { props.data.map( (item, key) => innerComponent(item, key) ) }         
         </div> 
      )
   } else {
      return ResponseAlert({code:204, message: "No Content"}) // 204 = no content
   }

}

/*
   ----------------------------
   loading icon spinner
   ----------------------------
*/ 
const Loading = () => {
   return (
      <div className="loading">
         <img src={loading}/>
      </div>
   );
}




/*
   --------------------------
   add to calender button 
   --------------------------
*/ 

const AddtoCalender = (props) =>{
   return (
      <div title="Add to Calendar" className="addeventatc">
        Add to Calendar
          <span className="start">{props.checkout.date.replaceAll('-','/')} {props.time}</span>
          <span className="title">Appointment with Big Apple Barbers</span>
          <span className="location">422 E 14th St, New York, NY 10009, United States</span>
          <span className="description">
             Hi { props.checkout.first_name } { props.checkout.last_name },
             you have an appointment with { props.checkout.barber_name } on { props.checkout.date } at { props.time }. 
             You have selected { props.checkout.services } that cost { props.checkout.price }. Please don't forget to join us. 
             Thanks  
          </span>
      </div>
   )
} 

/*
   -----------------------------
   Slot set
   -----------------------------
*/ 

const slotset = new Set(['slot_1', 'slot_2', 'slot_3', 'slot_4', 'slot_5', 'slot_6', 'slot_7', 'slot_8', 'slot_9', 'slot_10', 'slot_11', 'slot_12', 'slot_13', 'slot_14', 'slot_15', 'slot_16', 'slot_17', 'slot_18', 'slot_19'])

export { 
   Header, PageTitle, ServiceItem, Haircuts, makeDate,
   ResponseAlert, Loading, 
   slotToTime, oneHourSlot, AddtoCalender, onlyAvailableTimeSlot,
   time24, slotset, currentTime, nextTimeSlot, indexToWeekDay
};