import * as moment from 'moment';

/*------------------------
set cookies
--------------------------*/ 
const setCookie = (cname, cvalue, exdays) => {
   var d = new Date();
   d.setTime(d.getTime() + (exdays*24*60*60*1000));
   var expires = "expires="+ d.toUTCString();
   document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

/*------------------------
get cookies
--------------------------*/ 
const getCookie = (cname) => {
   var name = cname + "=";
   var decodedCookie = decodeURIComponent(document.cookie);
   var ca = decodedCookie.split(';');
   for(var i = 0; i <ca.length; i++) {
     var c = ca[i];
     while (c.charAt(0) === ' ') {
       c = c.substring(1);
     }
     if (c.indexOf(name) === 0) {
       return c.substring(name.length, c.length);
     }
   }
   return "";
}

/*------------------------
Delete Cookies
--------------------------*/ 
const deleteCookie = (name) => {
   setCookie(name, "", null , null , null, 1);
}

/*------------------------
Convert Base64 Image
--------------------------*/ 
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

/*------------------------
number to day convert
--------------------------*/ 
const getDay = (no) => {
  let day = 'Sat';
  switch (no) {
     case 1: day = 'Sun'; break;
     case 2: day = 'Mon'; break;
     case 3: day = 'Tue'; break;
     case 4: day = 'Wed'; break;
     case 5: day = 'Thu'; break;
     case 6: day = 'Fri'; break;
     case 7: day = 'Sat'; break;
  }
  return day;
}

/*------------------------
number to day convert
--------------------------*/ 
/**
 * @param {date|moment} start The start date
 * @param {date|moment} end The end date
 * @param {string} type The range type. eg: 'days', 'hours' etc
 */

const getRange = (start, end, type) => {
  type = type || 'days';
  let 
    range = moment().range(start, end), /*can handle leap year*/ 
    array = Array.from(range.by(type)); /*days, hours, years, etc.*/ 
  return  array.map(m => m.format("M-D-Y"));
}





// maxDeviation is the difference that is allowed default: 50kb
// Example: targetFileSizeKb = 500 then result will be between 450kb and 500kb
// increase the deviation to reduce the amount of iterations.

const  urltoFile = (url, filename, mimeType) => {
  return (fetch(url)
          .then(function (res) {
              return res.arrayBuffer();
          })
          .then(function (buf) {
              return new File([buf], filename, {type: mimeType});
          })
    );
}


const resizeImage = async (dataUrl, targetFileSizeKb, maxDeviation = 50) => {

  let originalFile = await urltoFile(dataUrl, 'test.png', 'image/png');
  
  if (originalFile.size / 1000 < targetFileSizeKb)
      return dataUrl; // File is already smaller

  let low = 0.0;
  let middle = 0.5;
  let high = 1.0;

  let result = dataUrl;

  let file = originalFile;

  while (Math.abs(file.size / 1000 - targetFileSizeKb) > maxDeviation) {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      const img = document.createElement('img');

      const promise = new Promise((resolve, reject) => {
          img.onload = () => resolve();
          img.onerror = reject;
      });

      img.src = dataUrl;

      await promise;

      canvas.width = Math.round(img.width * middle);
      canvas.height = Math.round(img.height * middle);
      context.scale(canvas.width / img.width, canvas.height / img.height);
      context.drawImage(img, 0, 0);
      file = await urltoFile(canvas.toDataURL(), 'test.png', 'image/png');

      if (file.size / 1000 < (targetFileSizeKb - maxDeviation)) {
          low = middle;
      } else if (file.size / 1000 > targetFileSizeKb) {
          high = middle;
      }

      middle = (low + high) / 2;
      result = canvas.toDataURL();
  }

  return result;
}



export {setCookie, getCookie, deleteCookie, getBase64, getDay, getRange, resizeImage};