import { useContext } from 'react';
import { Button, Select, Checkbox, Input, Collapse, DatePicker } from 'antd';
import { slotset, slotToTime, indexToWeekDay, makeDate } from './../../utils'; 
import { HeaderContext } from '../../api/api-context';
import Context from './context';

/**
 * 
 * 
 * 
 * Weekly and custom day off 
 * 
 */ 
export default function Accordion(props) {


    const { allBarber, dateFormat, dayoff } = useContext(HeaderContext)
    const { breakTimeState, setBreakTimeState, disableSlot, freeSlot, getBookedSlot } = useContext(Context)

    const onCollapseExpand = index => {
        if( index ) {
            const date = breakTimeState.time[index].date;
            const username = breakTimeState.type;
            if( breakTimeState.mode === 'custom' && username !== 'all' ) {
                getBookedSlot( username, date, index );
            }
        }
    }

    return (
        <div className="break-time-layout__item">
        <label className="label"> Add one/multiple { breakTimeState.mode } item </label>
        <div className="content">

        <Collapse accordion onChange={ onCollapseExpand }>
            { 
                breakTimeState.time?.map( (item, key) => 
                <Collapse.Panel 
                    key={key}
                    header={ breakTimeState.mode === 'custom' ? item.date : indexToWeekDay(item.date)  } 
                        /**
                         * 
                         * 
                         * Date Accordion Delete Button 
                         * 
                         * 
                         */ 
                        extra={
                        <Button 
                            size="small" 
                            type="danger"
                            onClick={ ev =>{
                                ev.preventDefault();
                                ev.stopPropagation();
                                setBreakTimeState( prevState => {
                                    const newState = prevState.time.filter( (ele, index) => index !== key );
                                    return {...prevState, time: newState }
                                } );
                            } }
                        > Remove
                        </Button>
                        }
                >
                    {/**
                     * 
                     * 
                     * 
                     * date picker / calendar 
                     * 
                     * 
                     */
                    breakTimeState.mode === 'custom' &&
                    <div className="break-time-layout__item">
                        <label className="label"> Select a date </label>
                        <div className="content">
                            <DatePicker 
                                inputReadOnly 
                                size="large"
                                disabled={ disableSlot }
                                allowClear={false}
                                format={dateFormat}
                                style={{width: '100%'}}
                                defaultValue={ makeDate(item.date) }
                                disabledDate={ value => {
                                    // disable previous dates
                                    if( value.format(dateFormat) === makeDate().format(dateFormat) || value.isAfter() ) {
                                        // disable weekends   
                                        if( allBarber[ breakTimeState.type ]?.weeklyOff?.includes( value.day() + 1 ) ) return true;
                                        // disable specific dayoff
                                        if( dayoff[breakTimeState.type]?.includes( value.format( dateFormat ) ) ) return true;
                                        //disable global dayoff
                                        if( dayoff?.all?.includes( value.format( dateFormat ) ) ) return true;
                                        
                                    } else {
                                        return true
                                    }
                                } }
                                onChange={ value => {
                                    const date = value.format( dateFormat );
                                    
                                    if( breakTimeState.mode === 'custom' && breakTimeState.type !== 'all' ) {
                                        getBookedSlot( breakTimeState.type, date );
                                    }

                                    setBreakTimeState( prevState => {  
                                        item.date = date;
                                        item.slot = [];
                                        prevState.time[key] = item;
                                        return {...prevState}
                                    } )

                                } }
                            />
                        </div>
                    </div>
                    }
                    {/**
                     * 
                     * 
                     * Weekly based break time : days in week
                     * 
                     * 
                     */
                    
                    breakTimeState.mode === 'weekly' && 
                    <div className="break-time-layout__item">
                        <label className="label"> Select Days</label>
                        <div className="content">
                            <Select
                                placeholder="Select days"
                                style={{ width: '100%' }}
                                size="large"
                                defaultValue={ item.date }
                                onChange={ value => {
                                setBreakTimeState( prevState => {  
                                    item.date = Number(value)
                                    prevState.time[key] = item;
                                    return {...prevState}
                                } )
                                } }
                                >
                                { (()=>{
                                    const options = [];
                                    for( let i = 0; i <= 6; i++ ) {
                                        if( !allBarber[breakTimeState.type].weeklyOff?.includes( i + 1 ) ) {
                                            options.push( <Select.Option key={i} value={i}>{ indexToWeekDay( i ) }</Select.Option> ) 
                                        }
                                    }
                                    return options;
                                })() }
                            </Select>
                        </div>
                    </div>
                    }
                    {/**
                     * 
                     * 
                     * 
                     * master switch for disabling/enabling all time slots 
                     * 
                     * 
                     */}
                    <div className="break-time-layout__item">
                    <label className="label"> Select Break Time Slots</label>
                    <div className="content">
                        <Checkbox.Group 
                            value={item.slot} 
                            style={{ width: '100%' }} 
                            disabled={disableSlot}
                            className="timeslot-checkbox" 
                            onChange={ val => {
                                setBreakTimeState( prevState => {  
                                item.slot = val;
                                prevState.time[key] = item;
                                return {...prevState}
                                } )
                            }} 
                        >
                        {
                            (()=>{
                                if( breakTimeState.mode === 'custom' && breakTimeState.type !== 'all' && freeSlot[breakTimeState.type] ) {
                                    const slot = freeSlot[breakTimeState.type];
                                    if( slot[item.date] ) {
                                        return slot[item.date].map( item => <Checkbox key={item} value={item}> { slotToTime(item) } </Checkbox> )
                                    }
                                } else {
                                    return Array.from(slotset).map( item => <Checkbox key={item} value={item}> { slotToTime(item) } </Checkbox> )
                                }
                            })()
                        }

                        </Checkbox.Group>
                    </div>
                    
                    </div>
                    {/**
                     * 
                     * 
                     * 
                     * brak time note : textarea 
                     * 
                     * 
                     */}
                    <div className="break-time-layout__item">
                        <label className="label"> Write a note </label>
                        <div className="content">
                            <Input.TextArea 
                                rows={3}
                                value={ item.note } 
                                onChange={ val => {
                                setBreakTimeState( prevState => {  
                                    item.note = val.target.value;
                                    prevState.time[key] = item;
                                    return {...prevState}
                                } )
                                } } 
                            />
                        </div>
                    </div>

                </Collapse.Panel>
                )
            }
            
        </Collapse>
        
        {props.children}

        </div>
    </div>
    )
}