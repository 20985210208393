import {useState, useEffect, useRef, useContext} from 'react';
import { Form, Input, Button, Drawer, Checkbox, Upload, notification, Select  } from 'antd';
import { PlusOutlined, LoadingOutlined, CloseOutlined  } from '@ant-design/icons';
import { Loading}  from './../utils';
import { getCookie } from './helper';
import { HeaderContext } from './../api/api-context';

/** 
@method getDay converts day number line [1,2,3] to string [sunday, monday, tuesday]
@method getBase64 used to convert user uploaded photo to base64 codeo so that it can be sent to server easily 
*/ 
import { getDay, getBase64 } from './helper';
import useSound from 'use-sound';
import warningSound from './../assets/sounds/warning.mp3';
import Api_Request from './../api/api-request';

const { Option } = Select;
const api = new Api_Request();

/*--------------------------------
Barbers main components
----------------------------------*/ 
const Barbers = () => {

   const [loginLoading, setLoginLoading] = useState(false);  /** @const loginLoading used to enable or disble login loading icon while verifity users from server */ 
   const [visible, setVisible] = useState(false);  /** @const visible used to open or close the dware */ 
   const [playWarningSound] = useSound(warningSound); 
   const [photo, setPhoto] = useState(''); /** @const photo contains barber photo */ 
   const [loading, setLoading] = useState(false); /** @const loading used to manage loading icon for edit , add a new stylist and delete button  */ 
   const [fileList, setFileList] = useState([]); /** @const fileList this const contains the upoaded photo details  */ 
   const [edit, setEdit] = useState(false); /** @const edit contains is the dware for edit more or add new user mode (true or false)  */ 
   const [target, setTarget] = useState(''); /** @const target contains specific barber from the specific barber array (barbers constant)  */ 
   let usernames = []; /** @variables username contains all the username from barbers to make usernamename validation easy  */ 
   const formRef = useRef(); /** @const formRef contains the dom and metod used for form*/ 
   const [pageLoaded, setPageLoaded] = useState(false);
   const [skills, setSkills] = useState([]);
   const { barberData, serBarberData, adminUsers, setAdminUsers } = useContext( HeaderContext )

/*---------------------------
   Initial data fetch
-----------------------------*/ 

useEffect(()=>{
   updatUserList()
}, [barberData])

const updatUserList = () => {
   const userName = getCookie('username');
   const sid = getCookie('sid');

   // must to check weather username and sid is set to the cookies
   if( userName && sid ) {
      const args = {
         endpoint: '?userlist',
         body: {
            username: JSON.parse(userName),
            sid : JSON.parse(sid)
         }
      }
      
      // call api when username is not available in state 
      if( !adminUsers.length) {
         api.post(args, res => {
            if( res.code === 200 && res.body.content !== null) {
               setPageLoaded(true) 
               setVisible(false);
               setAdminUsers(res.body)
            } 
         })
      } else {
         setPageLoaded(true)
         setVisible(false);
      }
   }

}


/**
@FUNCTION showDware used to open dware when "add a new stylist button is clicked"
---------------------------------------------------------------------------------- 
*/ 
const showDrawer = () => {
   setLoginLoading(false) // set login button loading false
   setEdit(false); // make edit false 
   setSkills([]); // set skill 0
   setFileList([]); // reset uploaded photo list
   setPhoto('');  // reset photo list
   formRef.current?.resetFields(); // rest form data
   setVisible(true) // open dware
};



/**
@FUNCTION used to open dware with edit mode
--------------------------------------------
*/ 
const openEditDware = key => {
   setTarget(key); // set target key so that on form change specific value can be edited
   setEdit(true); // set edit mode true
   formRef.current?.setFieldsValue(barberData[key]); // set specific details to the form accoridng to the edit button barber inforamtion
   
   const url = barberData[key].photo;
   const filename = url.substring(url.lastIndexOf('/')+1);

   setFileList([
      {
         uid: key,
         name: filename,
         status: 'done',
         url: url,
      }
   ])

   setPhoto(url);

   if( barberData[key].skills ) {
      setSkills(barberData[key].skills)
   } else {
      setSkills([])
   }

   setVisible(true) //open dware
}

/**
@FUNCTION On form input change
--------------------------------------------
*/ 
/**
@param barbers[target][name] 
@barbers barbers is the main array containing all barbers information, 
@target renfers to the specific index which is being edied
@names is the input field that is being changed
@value value is the field name which is being changed
*/ 
const onFormChange = (arr) => {
   const name  = arr[0].name[0];
   const value = arr[0].value;

   if( edit && name !== 'skills' && name !== 'photo' ) {
      barberData[target][name] = value;
      serBarberData(barberData);
   }

   if( edit && name !== 'skills' && name === 'photo' ) {
      if( value.file.status === 'removed' ) {
         barberData[target][name] = '';
      } else {
         barberData[target][name] = `${api.apiRoot}upload/${value.file.name}`;
      }
      serBarberData(barberData);
   }

}
      


/*---------------------------
   Delete Barbers from list
-----------------------------*/ 
const deleteBarberHandler = key =>  {
   setLoginLoading(true)
   let aa = [...barberData]; // copy parent barber variables info
   aa.splice(key, 1); // delete specific barber by index
   let data = {
      endpoint: '?barbers',
      body: aa
   }

   api.post(data, res => {
      setLoginLoading(false)
      if( res.code === 202 ) {
         serBarberData(res.body);
      } else {
         notification.open({
            message: <h3> Sorry Can not delete user </h3>,
            });
         playWarningSound();
      }
   })

}


/*---------------------------
Upload Image on server
-----------------------------*/ 
const uploadImage = async f => {

   getBase64(f).then(res =>{
      api.upload({
         file: {
            name: f.name.replaceAll(" ", '_'),
            base: res,
         },
         action: 'upload'
      }, callback => {
         
         if( callback.code === 409 || callback.code === 202 ) {
            setPhoto(`${api.apiRoot}upload/${f.name}`);
            setFileList([
               {
                  uid   : f.uid,
                  name  : f.name,
                  status: 'done',
                  url   : `${api.apiRoot}upload/${f.name}`,
               }
            ])
         } else {
            notification.open({
               message: <h3> Sorry. Can not create a service </h3>,
            });
            playWarningSound();
         }

         setLoading(false);
      })
   })
   
   
}


const handleChange = ({ fileList }) => {
   setPhoto('');
   setLoginLoading(false)
   setFileList(fileList)
};


/*-----------------------------
    on Form submit
    when form is submitted from dware (edit or create)
-------------------------------*/ 
const onFormSubmit = (values) => {
   
   values['skills'] = skills;

   if( photo.length || edit) {
      setLoginLoading(true)
      
      if( !edit ) {
         values.username = values.username.replaceAll(' ', "_");
         values.photo = photo;

         if( usernames.indexOf(values.username) !== -1 ) {
            setLoginLoading(false)
            notification.open({
               message: <h3> Change The Username. It's already taken </h3>,
               });
            playWarningSound();
            return false;
         }
      }

      let data = {
         endpoint: '?barbers',
         body: edit ? barberData : [...barberData, values]
      }

      api.post(data, res => {
         setLoginLoading(false)
         if( res.code === 202 ) {
            serBarberData(res.body);
            setVisible(false)
         } else {
            notification.open({
               message: <h3> Sorry Can not create new barber </h3>,
               });
            playWarningSound();
         }
      })
      
   } else {
      notification.open({
         message: <h3> Upload Photo </h3>,
      });
      playWarningSound();
   }     
   
};


const onSkillChangeHandler = (key, value, type) => {

      if( type === 'button' ) {
         setSkills(preState => [...preState, {value:'', sign: 'good'}])
      }

      if( type === 'value' || type === 'sign') {
         const copySkills = [...skills];
         copySkills[key][type] = value
         setSkills(copySkills);
       
         if( edit ) {
            barberData[target]['skills'] = copySkills
            serBarberData(barberData)
         }
      }

      if( type === 'remove-button' ) {
         skills.splice(key, 1);
         setSkills([...skills])
         if( edit ) {
            barberData[target]['skills'] = [...skills]
            serBarberData(barberData)
         }
        
      }
}
   
/*======================================
   Markup for barber details page
=======================================*/ 


const uploadButton = () => (
   <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
   </div>
);

const renderBarber = (key, item) => {
      usernames.push(item.username);
      function dayoff (day, k) {
         return (
            <span key={k} className="days"> {getDay(day)}, </span>
         )
      }

      return(
         <div key={key} className="admin-barber-item">
             <div className="stylist-image">
                <img src={item.photo} alt="stylist image"/>
             </div>
             <div className="stylist-content">
                  <p className="text-info"> <strong> Name :</strong> <span> {item.name} </span> </p>
                  <p className="text-info"> <strong> Position :</strong> <span> {item.position} </span> </p>
                  <p className="text-info"> <strong> Username :</strong> <span> {item.username} </span> </p>
                  <p className="text-info"> <strong> Weekly Off :</strong> {item.weeklyOff.map((item, k) => dayoff(item, k))} </p>
                  <p className="text-note">  {item.notice} </p>
                  <p className="text-btns">  
                     <Button type="primary" loading={loginLoading} onClick={()=> deleteBarberHandler(key)} danger >Delete</Button> 
                     <Button type="primary" onClick={()=>openEditDware(key)} >Edit</Button>
                  </p>
             </div>
         </div>
      )
}


return (
   <> {!pageLoaded ? <Loading/> : 
   <div className="admin-barber-container">
      {/**  
        @name barber-item-holder contains all the barber details markup
      */}
      <div className="barber-item-holder">
         {barberData.map((item, key) => renderBarber(key, item))}
      </div>

      {/**  
        @name barber-item-footer contains all the barber details markup
      */}
      <div className="barber-item-footer">
         <Button type="primary" size="large" onClick={showDrawer}>Add a New Stylist</Button>
      </div>

      <Drawer
         title={edit ? 'Edit this barber' : 'Add a new Barber'}
         className="admin-barbers-drawer"
         placement="right"
         closable={true}
         forceRender
         onClose={() => setVisible(false)}
         visible={visible}
      >

         <Form
            name="service-form"
            className="service-form"
            id="ashraf-edit-dware-form"
            onFinish={onFormSubmit}
            onFieldsChange={onFormChange}
            ref={formRef}
            layout="vertical"
         >

         <Form.Item label="Full Name"  name="name" rules={[{ required: true, message: 'Enter Full Name' }]} >
            <Input  placeholder="Full Name" />
         </Form.Item>

         <Form.Item label="Position" name="position" rules={[{ required: true, message: 'Enter Position' }]} >
            <Input  placeholder="Position" />
         </Form.Item>

         <Form.Item label="Username"  name="username" rules={[{ required: true, message: 'Enter username' }]} >
            <Select disabled={edit}>
               {adminUsers?.map((u, k) => <Option value={u.username} key={k + 1}> {u.data.name} </Option>)}
            </Select>
         </Form.Item>

         <Form.Item label="Barber Specialties"   name="skills" rules={[{ required: false, message: 'Enter Skills' }]} >
            <div>
               { skills?.map( (inp, key) =>  
               
               <div key={key} className="barber-specialties-input">
                  <Select value={ inp.sign } onSelect={(val)=> onSkillChangeHandler(key, val, 'sign')} >
                     <Select.Option value="good">Good</Select.Option>
                     <Select.Option value="bad">Bad</Select.Option>
                  </Select>
                  <Input onChange={(val)=> onSkillChangeHandler(key, val.target.value, 'value')}  value={inp.value}/> 
                  <Button type="danger" onClick={()=> onSkillChangeHandler(key, null, 'remove-button')}>  <CloseOutlined /> </Button>
               </div>
               
               ) }
               <Button size="large" onClick={()=> onSkillChangeHandler(null, null, 'button')} style={{marginTop: '5px',display: 'block', width:'100%'}} type="primary">Add Barber Specialties</Button>
            </div>
           
         </Form.Item>


         <Form.Item label="Notice" name="notice" rules={[{ required: true, message: 'Type notice' }]}>
            <Input.TextArea
               placeholder="Type notice"
               autoSize={{ minRows: 3, maxRows: 5 }}
            />
         </Form.Item>

         <label>Mark weekly Dayoff</label>
         <Form.Item  name="weeklyOff">
            <Checkbox.Group
               options={[
                  { label: 'Sun', value: 1 },
                  { label: 'Mon', value: 2 },
                  { label: 'Tue', value: 3 },
                  { label: 'Wed', value: 4 },
                  { label: 'Thu', value: 5 },
                  { label: 'Fri', value: 6 },
                  { label: 'Sat', value: 7 },
                  ]}
            />
         </Form.Item>
         <Form.Item name="photo">
            <Upload
                  action={uploadImage}
                  listType="picture-card"
                  fileList={fileList}
                  previewVisible={true}
                  beforeUpload={() => setLoading(true)}
                  onChange={handleChange}
               >
                  {fileList.length >= 1 ? null : uploadButton()}
            </Upload>
         </Form.Item>

         <Form.Item>
            <Button style={{width: '100%'}} size="large" loading={loginLoading}  type="primary" htmlType="submit" className="login-form-button">
               {edit ? 'Save Changes': 'Create a New Barber'}
            </Button>
         </Form.Item>

         </Form>

      </Drawer>

   </div> }
   </>
);
}


export {Barbers};