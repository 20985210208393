import { Fragment, useContext, useState, useEffect, useRef } from "react"
import { Loading, slotToTime, onlyAvailableTimeSlot} from "./utils"
import { notification, Radio  } from 'antd';
import { HeaderContext } from "./api/api-context";

import warningSound from './assets/sounds/warning.mp3';
import Api_Request from './api/api-request';
import useSound from 'use-sound';

const api = new Api_Request();

/**
 * 
 * 
 * Timeslots component 
 * 
 */ 
export default function Timelots( {date, loading, setLoading, onChange, defaultValue, onRender} ) {

    const [ playWarningSound ] = useSound(warningSound);
    const [ availableSlot, setAvailableSlot ] = useState({
        available: false,
        slot:[]
    });
    
    const { serviceOne, serviceTwo, timeslot, setTimeSlot, breakTime, dayoff } = useContext( HeaderContext );
    const timeslotRef = useRef(null);

    useEffect(()=>{

        if( !date ) {
            return false;
        }
        
        /**
         * 
         * Bring timeslot into screen 
         */ 
        if( timeslotRef.current ) {
            timeslotRef.current.scrollIntoView({behavior: "smooth", block: "end"});
        }

        // return if the timeslot already available
        if( timeslot[serviceOne.time][serviceTwo.username] && timeslot[serviceOne.time][serviceTwo.username].hasOwnProperty( date )  ) {
            /**
             * 
             * 
             * Check break time with available timeslot
             * 
            */
            const data = onlyAvailableTimeSlot({
                type: serviceTwo.username,
                time: serviceOne.time,
                slot: timeslot[serviceOne.time][serviceTwo.username][date],
                weeklyoff: serviceTwo.weeklyOff,
                date ,
                dayoff,
                breakTime,
            })
            
            onRender( data.slot.includes( defaultValue ))
            setAvailableSlot( data )
        /**
         * 
         * 
         * If teh date is not dayoff, fetch all the available timeslot for the selected date
         * 
         */
        } else {
            setLoading(true);
            api.get(`/?timeslot&b=${serviceTwo.username}&d=${date}`, res => {
                setLoading(false);
    
                if( res.code === 200 ) {
    
                    let response = !Array.isArray(res.body) ? Object.values(res.body) : res.body;
    
                    if( response.length ) {
    
                        /**
                         * 
                         * Store the timeslot 
                         * 
                         */ 
                        setTimeSlot( prevState => {
                            prevState[serviceOne.time][serviceTwo.username.trim()] = {
                                ...prevState[serviceOne.time][serviceTwo.username.trim()],
                                [date] : response
                            };
                            return prevState
                        } );
                        /**
                         * 
                         * 
                         * Check break time with available timeslot
                         * 
                        */
                        const data = onlyAvailableTimeSlot({
                            type: serviceTwo.username,
                            time: serviceOne.time,
                            weeklyoff: serviceTwo.weeklyOff,
                            slot: response,
                            date,
                            dayoff,
                            breakTime,
                        })

                        onRender( data.slot.includes( defaultValue ))
                        setAvailableSlot( data )
    
                    } 
               
                }  else {
                    playWarningSound();
                    notification.open({
                        message: <h3>Something went wrong!</h3>,
                        description: <p>Please try again or contact Big Apple Barbers</p>
                    });
                }
            }) // end of api call
        }

    }, [date]) //end of use effect


    /**
     * 
     * 
     *  Print timeslot
     * 
     */ 

    return (
        <div className="checkout-timeslot" ref={timeslotRef}>
            { loading ? <Loading/> : 
            <Fragment>
                <h2> Select from available time <span style={{color: 'red', fontSize: '30px', verticalAlign: 'middle', lineHeight: '8px'}}>*</span> </h2>
                <Radio.Group value={defaultValue} onChange={ ev => {
                    onRender( availableSlot.slot.includes( ev.target.value ))
                    onChange( ev );
                } } buttonStyle="solid" className="checkout-timeslot__time">
                    { !availableSlot.available && <p className="alert-no-timeslot"> No timeslots available for <strong>{ date }.</strong> Change the date </p> }   
                    { 
                        availableSlot.available && 
                        availableSlot.slot.map( slot => <Radio.Button value={slot} key={slot}> {slotToTime(slot)} </Radio.Button> ) 
                    }
                </Radio.Group>
            </Fragment>
            }
        </div>
    )
}