import { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { HeaderContext } from './../api/api-context';
import { Menu, Button, Avatar } from 'antd';
import { deleteCookie } from './helper';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  CalendarOutlined,
  ScissorOutlined,
  UserOutlined,
  DollarCircleOutlined,
  CoffeeOutlined,
  InfoCircleOutlined,
  BoldOutlined,
  DatabaseOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons';


const DashboardHeader = () => {
  
  const [ collapsed, setCollapsed ] = useState(false);
  const { user, setUser, setLoginLoadings } = useContext( HeaderContext );
  const [ path, setPath ] = useState('');
  const utype = user.type


  useEffect(()=>{
    setPath(window.location.pathname)
  })

  const logoutHandler = () => {
    setUser(false);
    setLoginLoadings(false);
    deleteCookie('username');
    deleteCookie('sid');
  }

  return (

  <div className="admin-header">

     <div className="admin-header__top">
         <Button className="admin-header__top--btn" type="primary" onClick={() => setCollapsed(!collapsed)}>
            {collapsed ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/>}
         </Button>
         <h4 className="admin-header__top--title">Big Apple Barbers</h4>
         
         <div onClick={logoutHandler} className="avator-icon">
            <span className="logout">Logout</span>
            <Avatar size={40} icon={<UserOutlined />} />
         </div>
     </div>

     <Menu
       selectedKeys={[path]}
       defaultOpenKeys={['sub1']}
       mode="inline"
       inlineCollapsed={collapsed}
     >

       <Menu.Item key="/app/admin" icon={<CalendarOutlined />}>
         <Link to="/admin"> Appointment </Link> 
       </Menu.Item>
       
       {
        utype === 'admin' ? 
        <>
          <Menu.Item key="/app/admin-services" icon={<ScissorOutlined />}>
            <Link to="/admin-services"> Services </Link> 
          </Menu.Item>
          
          <Menu.Item key="/app/admin-variation-price" icon={<DollarCircleOutlined />}>
            <Link to="/admin-variation-price"> VIP Pricing </Link> 
          </Menu.Item>

          <Menu.Item key="/app/admin-barbers" icon={ <BoldOutlined /> }>
            <Link to="/admin-barbers"> Barbers </Link> 
          </Menu.Item>

          <Menu.Item key="/app/admin-users" icon={<UserOutlined />}>
            <Link to="/admin-users"> Users </Link> 
          </Menu.Item>

          <Menu.Item key="/app/client-info" icon={<InfoCircleOutlined />}>
            <Link to="/client-info">Client Info</Link> 
          </Menu.Item>

          <Menu.Item key="/app/client-database" icon={<DatabaseOutlined />}>
            <Link to="/client-database">Client Database</Link> 
          </Menu.Item>
          
          <Menu.Item key="/app/break-time" icon={<ClockCircleOutlined />}>
            <Link to="/break-time">Break Time</Link> 
          </Menu.Item>
          
          <Menu.Item key="/app/dayoff" icon={<CoffeeOutlined />}>
            <Link to="/dayoff">Dayoff Settings</Link> 
          </Menu.Item>

          {/* <Menu.Item key="/app/individual-dayoff" icon={<CoffeeOutlined />}>
            <Link to="/individual-dayoff">Individual Dayoff</Link> 
          </Menu.Item>

          <Menu.Item key="/app/yearly-dayoff" icon={<PoweroffOutlined />}>
            <Link to="/yearly-dayoff"> General Dayoff </Link> 
          </Menu.Item> */}

        </> : null
      }

     </Menu>
   </div>
 );
  
}


export {DashboardHeader};